import React from 'react'
import Head from 'next/head'
import { AppProps } from 'next/app'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { CacheProvider, EmotionCache } from '@emotion/react'
import { ThemeSettings } from '../src/theme/Theme'
import createEmotionCache from '../src/createEmotionCache'
import { Provider } from 'react-redux'
import Store from '../src/store/Store'
import RTL from './../src/layouts/full/shared/customizer/RTL'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import { useSelector } from '../src/store/Store'
import { AppState } from '../src/store/Store'
import NextNProgress from 'nextjs-progressbar'
import BlankLayout from '../src/layouts/blank/BlankLayout'
import FullLayout from '../src/layouts/full/FullLayout'
import { LivepeerConfig } from '@livepeer/react'
import LivepeerClient from '../src/components/livepeer'

import '../src/_mockApis'
import '../src/utils/i18n'

// CSS FILES
import './app.scss'
import 'react-quill/dist/quill.snow.css'
import '../src/components/landingpage/testimonial/testimonial.css'
import '../src/components/landingpage/demo-slider/demo-slider.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { BackendProvider } from '../src/context/backend'
import { LensProvider } from '../src/context/lens'
import { SessionProvider } from '../src/context/session'
import Hotjar from '@hotjar/browser'
import { HOTJAR_PROJECT_ID } from '../src/utils/constants/api'
import { WagmiProvider } from 'wagmi'
import { wagmiConfig } from '../src/components/config/wallet.config'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ModalsProvider } from '@/context/modals'
import { NetworkProvider } from '@/context/network'
import { GoogleAnalytics } from 'nextjs-google-analytics'
import { IPFSStorageProvider } from '@/context/IPFSStorage'

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

const queryClient = new QueryClient()

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache
}

const layouts: any = {
  Blank: BlankLayout,
}

const MyApp = (props: MyAppProps) => {
  const {
    Component,
    emotionCache = clientSideEmotionCache,
    pageProps,
  }: any = props
  const theme = ThemeSettings()
  const customizer = useSelector((state: AppState) => state.customizer)
  const Layout = layouts[Component.layout] || FullLayout
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    setTimeout(() => setLoading(true), 1000)

    if (HOTJAR_PROJECT_ID !== 0) {
      Hotjar.init(HOTJAR_PROJECT_ID, 6)
    }
  }, [])
  return (
    <SessionProvider>
      <CacheProvider value={emotionCache}>
        <BackendProvider>
          <Head>
            <meta
              name="viewport"
              content="initial-scale=1, width=device-width"
            />
            <title>Handle finder</title>
          </Head>
          <NextNProgress color="#5D87FF" />
          <LivepeerConfig client={LivepeerClient}>
            <ThemeProvider theme={theme}>
              <RTL direction={customizer.activeDir}>
                <CssBaseline />
                {loading ? (
                  <Layout>
                    <Component {...pageProps} />
                  </Layout>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                      height: '100vh',
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
              </RTL>
            </ThemeProvider>
          </LivepeerConfig>
        </BackendProvider>
      </CacheProvider>
    </SessionProvider>
  )
}

export default (props: MyAppProps) => (
  <Provider store={Store}>
    <WagmiProvider config={wagmiConfig}>
      <IPFSStorageProvider>
        <QueryClientProvider client={queryClient}>
          <NetworkProvider>
            <ModalsProvider>
              <LensProvider>
                <GoogleAnalytics trackPageViews />
                <MyApp {...props} />
              </LensProvider>
            </ModalsProvider>
          </NetworkProvider>
        </QueryClientProvider>
      </IPFSStorageProvider>
    </WagmiProvider>
  </Provider>
)
