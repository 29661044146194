// ** React Imports
import { useCallback, useMemo } from 'react'

// ** MUI Imports
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { IconCheck } from '@tabler/icons-react'
import { IconReload } from '@tabler/icons-react'

// ** Utils & Types
import { SignatureStatus } from '.'

type BaseSignatureProps = {
  status: SignatureStatus
  setStatus: (status: SignatureStatus) => void
  callFunction: () => Promise<any | undefined>
  title: string
  subTitle?: string
  ctaLabel?: string
  ctaContent?: JSX.Element
  showTitleOnMobile?: boolean;
}

export const BaseSignature = ({
  status,
  setStatus,
  callFunction,
  title,
  subTitle,
  ctaLabel = 'Allow',
  ctaContent,
  showTitleOnMobile = false
}: BaseSignatureProps) => {
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));


  const handleCall = useCallback(async () => {
    setStatus('pending')
    try {
      const response = await callFunction()
      if (!response) throw new Error('Something went wrong')
      setStatus('success')
    } catch (error) {
      console.log('Error calling function', error)
      setStatus('error')
    }
  }, [setStatus, callFunction])

  const callToAction = useMemo(() => {
    switch (status) {
      case 'pending':
        return <CircularProgress size={20} />
      case 'success':
        return <IconCheck />
      case 'error':
        return (
          <IconReload
            onClick={handleCall}
            style={{ cursor: 'pointer' }}
          />
        )
      case 'disabled':
        return (
          <Button
            variant='contained'
            color='primary'
            size='small'
            disabled>
            {ctaLabel}
          </Button>
        )
      default:
        return (
          <Button
            variant='contained'
            color='primary'
            size='small'
            onClick={handleCall}>
            {ctaLabel}
          </Button>
        )
    }
  }, [status, handleCall, ctaLabel])

  if (isMdScreen && !showTitleOnMobile) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: 2,
          my: 0.5,
          mx: 0.5,
          borderRadius: "0.5rem",
          border: `1px solid ${theme.palette.divider}`,
          width: "100%",
          minHeight: "4rem",
        }}
      >
        {ctaContent ? ctaContent : callToAction}
      </Box>
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        p: 2,
        my: 0.5,
        borderRadius: '0.5rem',
        border: `1px solid ${theme.palette.divider}`,
        width: '100%',
        minHeight: '4rem'
      }}>
      <Stack
        direction={'row'}
        alignItems={'center'}
        gap={2}>
        <Stack
          direction={'column'}
          gap={0}>
          <Typography
            variant='subtitle1'
            color='textPrimary'>
            {title}
          </Typography>
          {subTitle && (
            <Typography
              variant='subtitle2'
              color='textSecondary'>
              {subTitle}
            </Typography>
          )}
        </Stack>
      </Stack>
      {ctaContent ? ctaContent : callToAction}
    </Box>
  )
}
