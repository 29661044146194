import { ProfileFragment } from '@lens-protocol/client'
import { CredentialType, LensClientStorageValue } from '../types/auth/auth'
import { SignInStatus, UserProfileData } from '../types/custom'
import { LensAuth } from './types'

const getLocalStorage = (key: string) => {
    const stored = localStorage.getItem(key)
    if (stored === 'undefined') return undefined
    return stored ? JSON.parse(stored) : undefined
}

const setLocalStorage = (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value))
}

const removeLocalStorage = (key: string) => {
    localStorage.removeItem(key)
}

export const getSignInStatus = () => {
    return getLocalStorage('signInStatus') as SignInStatus | undefined
}

export const setSignInStatus = (value: SignInStatus) => {
    setLocalStorage('signInStatus', value)
}

export const removeSignInStatus = () => {
    removeLocalStorage('signInStatus')
}

/**
 * Wipes the private keys from the local storage
 * @param walletAddress - The address to wipe the credentials from.
 * @param type - The type of the credential to wipe.
 */
export const wipeCredentials = (
    walletAddress: string,
    type: CredentialType,
    isProd: boolean
) => {
    const environment = isProd ? "production" : "development"
    const key = `${environment}.credentials:${walletAddress?.toLowerCase()}`
    const stored = localStorage.getItem(key)
    if (!stored) return

    const storedJSON = JSON.parse(stored)
    if (type === CredentialType.all) {
        localStorage.removeItem(key)
        return
    }

    if (storedJSON[type]) delete storedJSON[type]

    localStorage.setItem(key, JSON.stringify(storedJSON))
}

export const generateLensSignature = async (
    auth: (Id?: string | undefined) => Promise<LensAuth | undefined>,
    id?: string
): Promise<string | null> => {
    const authLens = await auth(id)
    if (!authLens) throw new Error('No lens auth')
    const jwt = localStorage.getItem('jwt')
    return jwt
}

export const formatLensFragmentData = (
    data: ProfileFragment | null | undefined
) => {
    return {
        profileId: data?.id,
        handle: data?.handle?.localName,
        name: data?.metadata?.displayName,
        bio: data?.metadata?.bio,
        picture: data?.metadata?.picture?.optimized?.uri,
        coverPicture: data?.metadata?.coverPicture?.optimized?.uri,
        followers: data?.stats.followers,
        following: data?.stats.following,
        posts: data?.stats.posts
    } as UserProfileData
}

/**
 * Stores the private keys in the local storage
 * @param walletAddress - The address to store the credentials for.
 * @param type - The type of the credential to store.
 * @param keys - The private keys to store.
 */
export const storeCredentials = (
    walletAddress: string,
    type: Exclude<CredentialType, CredentialType.all>,
    value: any,
    isProd: boolean,
) => {
    const environment = isProd ? "production" : "development"
    const key = `${environment}.credentials:${walletAddress?.toLowerCase()}`
    const stored = localStorage.getItem(key)

    const storedJSON = stored ? JSON.parse(stored) : {}

    storedJSON[type] = value

    localStorage.setItem(key, JSON.stringify(storedJSON))
}

/**
 * Gets the private keys from the local storage
 * @param walletAddress - The address to get the credentials from.
 * @param type - The type of the credential needed.
 * @returns Private keys for the given wallet address and type.
 */
export const loadCredentials = (
    walletAddress: string,
    type: Exclude<CredentialType, CredentialType.all>,
    isProd: boolean,
) => {
    const key = `credentials:${walletAddress?.toLowerCase()}`
    const stored = localStorage.getItem(key)
    if (stored) {
        localStorage.removeItem(key)
    }

    const environment = isProd ? "production" : "development"
    const keyWithEnv = `${environment}.credentials:${walletAddress?.toLowerCase()}`
    const storedWithEnv = localStorage.getItem(keyWithEnv)
    if (!storedWithEnv) return null
    const storedJSON = JSON.parse(storedWithEnv)

    let credential = storedJSON[type] ?? null

    return credential
}

export const getLensCrendentials = () => {
    const key = `lens.production.credentials`
    const stored = localStorage.getItem(key)
    if (!stored) return

    const storedJSON = JSON.parse(stored) as LensClientStorageValue
    return storedJSON as LensClientStorageValue
}

export const updateLensCrendentials = (value: string) => {
    const key = `lens.production.credentials`
    const stored = localStorage.getItem(key)
    if (!stored) return

    let storedJSON = JSON.parse(stored) as LensClientStorageValue
    storedJSON = {
        ...storedJSON,
        data: {
            refreshToken: value,
        },
    }

    localStorage.setItem(key, JSON.stringify(storedJSON))
}
