// ** MUI Imports
import { Box, Modal, Typography, Button, Stack } from '@mui/material'

// ** Hooks Imports
import { useSession } from '@/context/session'
import { useModalsActions, useModalsState } from '@/context/modals'
import BaseModal from './base'

// ** Types
import { NotConnectedCase } from './not-connected'

/**
 * Payload for the Confirm modal
 * @param label - The label to render.
 * @param checksBeforeSubmit - Function to do some checks before submission
 * @param handleSubmit - Function to handle stake
 */
export interface ConfirmPayload {
 label: string
 checksBeforeSubmit?: () => void
 handleSubmit: () => Promise<void>
}

/////////////////////////////////////
export const ConfirmModal = () => {
 const { open, close } = useModalsActions()
 const { confirm } = useModalsState()
 const { notLoggedIn, connectedNotLogged } = useSession()

 const handleCloseModal = () => close('confirm')

 const checks = () => {
  if (notLoggedIn) {
   handleCloseModal()
   open('notConnected', { modalCase: NotConnectedCase.NotConnected })
   return
  }

  if (connectedNotLogged) {
   handleCloseModal()
   open('notConnected', { modalCase: NotConnectedCase.PendingLogIn })
   return
  }

  if (confirm.data) {
   const { checksBeforeSubmit } = confirm.data
   checksBeforeSubmit?.()
  }
 }

 if (confirm.isOpen) {
  checks()
 }

 // Check if the modal has been opened with needed params
 if (!confirm.data) return null
 const { handleSubmit, label } = confirm.data

 const handleConfirmClick = async () => {
  handleCloseModal()
  await handleSubmit()
 }

 return (
  <>
   <Modal
    open={confirm.isOpen}
    onClose={handleCloseModal}>
    <BaseModal sx={{ justifyContent: 'center' }}>
     <Box>
      <Typography
       variant='subtitle1'
       textAlign='center'
       mb={2}>
       {label}
      </Typography>
      <Stack
       display='flex'
       flexDirection='row'
       justifyContent='center'
       gap={2}>
       <Button
        color='success'
        onClick={handleConfirmClick}>
        Confirm
       </Button>
       <Button
        color='error'
        onClick={handleCloseModal}>
        Cancel
       </Button>
      </Stack>
     </Box>
    </BaseModal>
   </Modal>
  </>
 )
}
