import {
  BrowserWalletImg,
  CoinbaseWalletImg,
  WalletConnectImg,
} from '@/utils/constants/images'

interface WalletDetails {
  logo: string
  name: string
}

/**
 * Returns an object with the name and logo URL for the specified wallet name.
 *
 * @param name The wallet name.
 * @returns An object with the name and logo URL.
 */
const getWalletDetails = (name: string): WalletDetails => {
  const walletDetails: Record<string, WalletDetails> = {
    'Coinbase Wallet': {
      logo: `${CoinbaseWalletImg}`,
      name: 'Coinbase Wallet',
    },
    WalletConnect: {
      logo: `${WalletConnectImg}`,
      name: 'WalletConnect',
    },
  }
  const defaultDetails: WalletDetails = {
    logo: `${BrowserWalletImg}`,
    name,
  }

  return walletDetails[name] || defaultDetails
}

export default getWalletDetails
