import { http, createConfig, fallback } from 'wagmi'
import { coinbaseWallet, injected, walletConnect } from 'wagmi/connectors'

import { W3M_PROJECT_ID } from '@/utils/constants/api'
import {
  LIBREE_NETWORKS,
  POLYGON_ID,
} from '@/utils/constants/networks'
import { POLYGON_RPCS } from '@/utils/constants/rpcs'

const metadata = {
  name: 'Handle Finder',
  description: 'Handle Finder frontend',
  url: '',
  icons: ['https://avatars.githubusercontent.com/u/37784886'],
}

const transports = {
  [POLYGON_ID]: fallback(POLYGON_RPCS.map((rpc) => http(rpc))),
}

const connectors = [
  injected(),
  coinbaseWallet({
    appName: metadata.name,
    chainId: POLYGON_ID,
  }),
  walletConnect({
    projectId: W3M_PROJECT_ID,
  }),
]

export const wagmiConfig = createConfig({
  chains: LIBREE_NETWORKS,
  transports,
  connectors,
  ssr: true,
})
