// ** React Imports
import {
  Dispatch,
  SetStateAction,
} from "react";

// ** MUI Imports
import {
  Box,
  Typography,
  useTheme,
} from "@mui/material";

// ** Hooks
import { useLens } from "@/context/lens";

// ** Components
import { BaseSignature } from "./BaseSignature";

// ** Utils & Types
import { SignatureStatus } from ".";

type LensSign = {
  status: SignatureStatus;
};

type LensSignatureProps = LensSign & {
  setStatus: Dispatch<SetStateAction<SignatureStatus>>;
};

export const LensEnableManager = ({
  status,
  setStatus,
}: LensSignatureProps) => {
  const { enableProfileManager } = useLens();
  const theme = useTheme();

  const handleEnableManager = async () => {
    setStatus("pending");
    try {
      await enableProfileManager()
      setStatus("success");
      return true
    } catch (error) {
      console.log("Error enabling lens profile manager", error);
      setStatus("error");
    }
  }

  if (status === "disabled") {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: 2,
          my: 0.5,
          borderRadius: "0.5rem",
          border: `1px solid ${theme.palette.divider}`,
          width: "100%",
          minHeight: "4rem",
        }}
      >
        <Typography
          variant="subtitle2"
          color="textSecondary"
          textAlign={"center"}
        >
          You must sign in with Lens first
        </Typography>
      </Box>
    );
  }

  return (
    <BaseSignature
      status={status}
      setStatus={setStatus}
      callFunction={() => handleEnableManager()}
      title={"Lens interaction"}
      showTitleOnMobile
      ctaLabel="Enable"
    />
  );
};
