import { Box, Skeleton, useTheme } from '@mui/material'

export const SkeletonSignature = () => {
 const theme = useTheme()

 return (
  <Box
   sx={{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    p: 2,
    my: 0.5,
    borderRadius: '0.5rem',
    border: `1px solid ${theme.palette.divider}`,
    width: '100%',
    minHeight: '4rem'
   }}>
   <Skeleton
    variant='text'
    width={'3rem'}
   />
   <Skeleton
    variant='rounded'
    width={'2.5rem'}
    height={'1rem'}
    sx={{
     marginRight: '0.1rem'
    }}
   />
  </Box>
 )
}
