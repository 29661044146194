import { FC } from 'react'
import { useSelector } from '../../../../store/Store'
import Link from 'next/link'
import { styled } from '@mui/material'
import { AppState } from '../../../../store/Store'
import Image from 'next/image'

const Logo = () => {
 const customizer = useSelector((state: AppState) => state.customizer)
 const LinkStyled = styled(Link)(() => ({
  height: customizer.TopbarHeight,
  width: customizer.isCollapse ? '50px' : '200px',
  overflow: 'hidden',
  display: 'block'
 }))

 if (customizer.activeDir === 'ltr') {
  return (
   <LinkStyled href='/'>
    {customizer.activeMode === 'dark' ? (
     <Image
      src='/images/logos/HandleFinderLogoSVG.svg'
      alt='logo'
      height={customizer.TopbarHeight}
      width={200}
      priority
     />
    ) : (
     <Image
      src={'/images/logos/HandleFinderLogoSVGBlack.svg'}
      alt='logo'
      height={customizer.TopbarHeight}
      width={200}
      priority
     />
    )}
   </LinkStyled>
  )
 }

 return (
  <LinkStyled href='/'>
   {customizer.activeMode === 'dark' ? (
    <Image
     src='/images/logos/HandleFinderLogoSVG.svg'
     alt='logo'
     height={customizer.TopbarHeight}
     width={200}
     priority
    />
   ) : (
    <Image
     src='/images/logos/HandleFinderLogoSVGBlack.svg'
     alt='logo'
     height={customizer.TopbarHeight}
     width={200}
     priority
    />
   )}
  </LinkStyled>
 )
}

export default Logo
