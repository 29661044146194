// ** React & Next & Mui
import { useEffect, useState } from 'react'
import Link from 'next/link'
import {
  IconButton,
  Dialog,
  DialogContent,
  Stack,
  Divider,
  Box,
  List,
  ListItemText,
  Typography,
  TextField,
  ListItemButton,
  Avatar,
} from '@mui/material'
import { IconSearch, IconX } from '@tabler/icons-react'

// ** Context & Utils
import { ProfileFragment } from '@lens-protocol/client'
import { useLens } from '@/context/lens'
import { getAvatarUrl } from '@/utils'

const Search = () => {
  const { getProfilesByHandle } = useLens()

  const [showDrawer2, setShowDrawer2] = useState(false)
  const [search, setSerach] = useState('')
  const [searchedUsers, setSearchedUsers] = useState<ProfileFragment[]>([])

  const handleDrawerClose2 = () => {
    setShowDrawer2(false)
  }

  useEffect(() => {
    searchUsers()
  }, [search])

  const searchUsers = async () => {
    if (!search) return
    const res = await getProfilesByHandle(search)

    setSearchedUsers(res.items)
  }

  return (
    <>
      <IconButton
        aria-label="show 4 new mails"
        color="inherit"
        aria-controls="search-menu"
        aria-haspopup="true"
        onClick={() => setShowDrawer2(true)}
        size="large"
      >
        <IconSearch size="20" />
      </IconButton>
      <Dialog
        open={showDrawer2}
        onClose={() => setShowDrawer2(false)}
        fullWidth
        maxWidth={'sm'}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ sx: { position: 'fixed', top: 30, m: 0 } }}
      >
        <DialogContent className="testdialog">
          <Stack direction="row" spacing={2} alignItems="center">
            <TextField
              id="tb-search"
              placeholder="Search users"
              fullWidth
              onChange={(e) => setSerach(e.target.value)}
              inputProps={{ 'aria-label': 'Search users' }}
            />
            <IconButton size="small" onClick={handleDrawerClose2}>
              <IconX size="18" />
            </IconButton>
          </Stack>
        </DialogContent>
        <Divider />
        <Box p={2} sx={{ maxHeight: '60vh', overflow: 'auto' }}>
          <Typography variant="h5" p={1}>
            Users
          </Typography>
          <Box>
            <List component="nav">
              {searchedUsers.map((user: ProfileFragment) => {
                return (
                  <Box key={user.id}>
                    {user ? (
                      <ListItemButton
                        sx={{ py: 0.5, px: 1 }}
                        href={`/profile/${user.id}`}
                        component={Link}
                      >
                        <Stack
                          direction="row"
                          spacing={1}
                          sx={{
                            '&:hover': {
                              cursor: 'pointer',
                              '& .avatar': {
                                filter: 'brightness(0.7)',
                                transition: '0.2s',
                              },
                            },
                          }}
                        >
                          <Avatar
                            className="avatar"
                            src={getAvatarUrl(user)}
                            alt="profileImage"
                            sx={{
                              borderRadius: '50%',
                              width: '50px',
                              height: '50px',
                              border: '2px solid #fff',
                            }}
                          />

                          <ListItemText
                            primary={
                              user.metadata?.displayName ||
                              user.handle?.localName
                            }
                            secondary={
                              user.handle?.suggestedFormatted.localName
                            }
                            sx={{ my: 0, py: 0.5 }}
                          />
                        </Stack>
                      </ListItemButton>
                    ) : (
                      ''
                    )}
                  </Box>
                )
              })}
            </List>
          </Box>
        </Box>
      </Dialog>
    </>
  )
}

export default Search
