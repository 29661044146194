import { Chain } from 'viem'
import { POLYGON_ID } from './networks'
import { ProjectModeType } from '@/types/custom'

export const PROJECT_MODE: ProjectModeType =
  (process.env.NEXT_PUBLIC_PROJECT_MODE as ProjectModeType) ?? 'development'

/* BASE URLs ======================================================================== */

export const BACKEND_URL =
  process.env.NEXT_PUBLIC_BACKEND_URL || 'http://localhost:3000'

export const ALCHEMY_API_KEY = process.env.NEXT_PUBLIC_ALCHEMY_API_KEY || ''

export const W3M_PROJECT_ID = process.env.NEXT_PUBLIC_PROJECT_ID || ''

export const LIVE_PEER_API_KEY = process.env.NEXT_PUBLIC_LIVEPEER_API_KEY || ''

export const HOTJAR_PROJECT_ID: number =
  Number(process.env.NEXT_PUBLIC_HOTJAR_PROJECT_ID) || 0

export const PINNING_PINATA_API_URL = 'https://api.pinata.cloud/pinning'

/* URLs PER NETWORK ================================================================= */

export const LENS_API_URL = {
  [POLYGON_ID]: 'https://api-v2.lens.dev',
}

/* API ENDPOINTS ==================================================================== */

type ApiEndpoint = 'lens'

type NetworkApiEndpoints = {
  [chainId in Chain['id']]?: {
    [key in ApiEndpoint]: string
  }
}

export const ALCHEMY_API_ENDPOINT = {
  [POLYGON_ID]: 'https://polygon-mainnet.g.alchemy.com/v2',
}

export const NETWORK_API_ENDPOINTS: NetworkApiEndpoints = {
  [POLYGON_ID]: {
    lens: LENS_API_URL[POLYGON_ID],
  },
}

/* .env TOKENS ====================================================================== */

export const PINATA_JWT = process.env.NEXT_PUBLIC_PINATA_JWT || ''
