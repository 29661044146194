import { uniqueId } from 'lodash'

interface MenuitemsType {
  [x: string]: any
  id?: string
  navlabel?: boolean
  subheader?: string
  title?: string
  icon?: any
  href?: string
  children?: MenuitemsType[]
  chip?: string
  chipColor?: string
  variant?: string
  external?: boolean
  isProductionMode: boolean
}
import {
  IconApps,
  IconAperture,
  IconUsers,
  IconUsersGroup,
  IconAward,
  IconList,
  IconPoint,
} from '@tabler/icons-react'
import { isProductionMode } from '@/utils'

const items: MenuitemsType[] = [
  {
    navlabel: true,
    subheader: 'Feed',
    isProductionMode: false,
  },
  {
    id: uniqueId(),
    title: 'Feed',
    icon: IconList,
    href: '/feed',
    chipColor: 'secondary',
    isProductionMode: false,
  },
  {
    navlabel: true,
    subheader: 'Trending',
    isProductionMode: true,
  },
  {
    id: uniqueId(),
    title: 'People',
    icon: IconUsers,
    href: '/',
    chipColor: 'secondary',
    isProductionMode: true,
    children: [
      {
        id: uniqueId(),
        title: 'Most liked',
        icon: IconPoint,
        href: '/?liked',
        isProductionMode: true,
      },
      {
        id: uniqueId(),
        title: 'Most followed',
        icon: IconPoint,
        href: '/?followed',
        isProductionMode: true,
      },
      {
        id: uniqueId(),
        title: 'Most collected',
        icon: IconPoint,
        href: '/?collected',
        isProductionMode: true,
      },
    ],
  },
  {
    id: uniqueId(),
    title: 'Content',
    icon: IconAperture,
    href: '/content',
    chipColor: 'secondary',
    isProductionMode: true,
    children: [
      {
        id: uniqueId(),
        title: 'Most liked',
        icon: IconPoint,
        href: '?liked',
        isProductionMode: true,
      },
      {
        id: uniqueId(),
        title: 'Most commented',
        icon: IconPoint,
        href: '/content?commented',
        isProductionMode: true,
      },
      {
        id: uniqueId(),
        title: 'Most quoted',
        icon: IconPoint,
        href: '/content?quoted',
        isProductionMode: true,
      },
      {
        id: uniqueId(),
        title: 'Most mirrored',
        icon: IconPoint,
        href: '/content?mirrored',
        isProductionMode: true,
      },
      {
        id: uniqueId(),
        title: 'Most collected free',
        icon: IconPoint,
        href: '/content?collectedFree',
        isProductionMode: true,
      },
      {
        id: uniqueId(),
        title: 'Most collected paid',
        icon: IconPoint,
        href: '/content?collectedPaid',
        isProductionMode: true,
      },
    ],
  },
  {
    id: uniqueId(),
    title: 'Collect',
    icon: IconAward,
    href: '/collect',
    chipColor: 'secondary',
    isProductionMode: false,
  },
  {
    navlabel: true,
    subheader: 'Communities',
    isProductionMode: true,
  },
  {
    id: uniqueId(),
    title: 'Orb clubs',
    icon: IconUsersGroup,
    href: '/orbClubs',
    isProductionMode: true,
  },
  {
    navlabel: true,
    subheader: 'Apps',
    isProductionMode: true,
  },
  {
    id: uniqueId(),
    title: 'Stats',
    icon: IconApps,
    href: '/apps',
    isProductionMode: true,
  },
]

const Menuitems = isProductionMode
  ? items.filter((item) => item.isProductionMode)
  : items

export default Menuitems
