import { useState, useEffect } from 'react'

export function useLocalStorage(key: string, initialValue: any) {
 // Get from local storage then
 // parse stored json or return initialValue
 const readValue = () => {
  if (typeof window === 'undefined') {
   return initialValue
  }

  try {
   const item = window.localStorage.getItem(key)
   return item ? JSON.parse(item) : initialValue
  } catch (error) {
   console.warn(`Error reading localStorage key “${key}”:`, error)
   return initialValue
  }
 }

 // State to store our value
 // Pass initial state function to useState so logic is only executed once
 const [storedValue, setStoredValue] = useState(readValue)

 // Return a wrapped version of useState's setter function that ...
 // ... persists the new value to localStorage.
 useEffect(() => {
  if (typeof window === 'undefined') {
   return
  }

  try {
   const serializedValue = JSON.stringify(storedValue)
   window.localStorage.setItem(key, serializedValue)
  } catch (error) {
   console.warn(`Error setting localStorage key “${key}”:`, error)
  }
 }, [key, storedValue])

 return [storedValue, setStoredValue]
}
