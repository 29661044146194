// ** React Imports
import { useEffect, useState } from 'react'

// ** Mui Imports
import { Modal, Stack } from '@mui/material'
import { IconX } from '@tabler/icons-react'

// ** Hooks
import { useModalsActions, useModalsState } from '@/context/modals'
import { useLens } from '@/context/lens'
import { useAccount } from 'wagmi'

// ** Components
import { LensProfileData, LensSignature } from './LensSignature'

import BaseModal from '../base'
import { SkeletonSignature } from './SkeletonSignature'
import { getSignInStatus, setSignInStatus } from '@/utils/helpers'
import { SignInStatus } from '@/types/custom'
import { LensEnableManager } from './LensEnableManager'

export type SignatureStatus =
    | 'pending'
    | 'success'
    | 'error'
    | 'disabled'
    | undefined

//////////////////////////////////
export const SignInProcessModal = () => {
    const [lensSign, setLensSign] = useState<SignatureStatus>('disabled')
    const [enableManager, setEnableManager] = useState<SignatureStatus>("disabled")
    const [lensProfiles, setLensProfiles] = useState<LensProfileData[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [renderEnableManager, setRenderEnableManager] = useState(false)

    const { signInProcess } = useModalsState()
    const { isOpen } = signInProcess
    const { auth: lensAuth, getManagedProfiles, isManagerEnabled } = useLens()
    const { close } = useModalsActions()
    const { address } = useAccount()

    useEffect(() => {
        if (!isOpen) return
        let isLensSuccess = false
        let isLensManagerEnabled = false

        if (lensAuth) {
            setLensSign('success')
            isLensSuccess = true

            if (isManagerEnabled === null) return
            if (isManagerEnabled) {
                setEnableManager("success")
                isLensManagerEnabled = true
            } else {
                setEnableManager(undefined)
                isLensManagerEnabled = false
                setRenderEnableManager(true)
            }

        } else {
            setLensSign(undefined);
            setEnableManager("disabled")
            getUserProfiles();
            isLensSuccess = false;
            isLensManagerEnabled = false
        }

        if (isLensSuccess && isLensManagerEnabled) {
            handleSignInComplete()
        } else {
            setSignInStatus(SignInStatus.pending)
        }

        return () => {
            cleanState()
        }
    }, [isOpen, lensAuth, isManagerEnabled])

    const getUserProfiles = async () => {
        if (!address) return
        try {
            setIsLoading(true)

            const managedProfiles = await getManagedProfiles(address)
            if (managedProfiles === undefined) {
                setLensProfiles([])
                return
            }
            const userManagedProfiles = managedProfiles.items

            const profiles = userManagedProfiles.map((profile) => {
                return {
                    id: profile.id,
                    handle: profile.handle?.localName || '',
                    name: profile.metadata?.displayName || '',
                    img: profile.metadata?.picture?.optimized?.uri || ''
                }
            })
            setLensProfiles(profiles)
        } catch (error) {
            console.log('Error getting lens profiles at modal', error)
        } finally {
            setIsLoading(false)
        }
    }

    const handleCloseModal = () => {
        const signInStatus = getSignInStatus()
        if (signInStatus !== SignInStatus.completed) return
        close('signInProcess')
    }

    const handleCloseManually = () => {
        close("signInProcess")
        if (!lensSign) {
            setSignInStatus(SignInStatus.rejected)
        }
    }

    const handleSignInComplete = () => {
        setSignInStatus(SignInStatus.completed)
        handleCloseModal();
    }

    const cleanState = () => {
        setLensProfiles([])
    }

    return (
        <>
            <Modal
                open={isOpen}
                onClose={handleCloseModal}>
                <BaseModal
                    title='Login'
                    action={
                        <IconX
                            onClick={handleCloseManually}
                            style={{ cursor: 'pointer' }}
                        />
                    }
                    sx={{
                        minHeight: '10rem',
                        justifyContent: 'center'
                    }}>
                    <>
                        <Stack
                            width={'100%'}
                            direction={'column'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            gap={1}
                            mt={1}>
                            {isLoading ? (
                                <SkeletonSignature />
                            ) : (
                                <>
                                    <LensSignature
                                        profiles={lensProfiles}
                                        status={lensSign}
                                        setStatus={setLensSign}
                                    />
                                    {renderEnableManager ? (
                                        <LensEnableManager
                                            status={enableManager}
                                            setStatus={setEnableManager}
                                        />
                                    ) : null}
                                </>
                            )}
                        </Stack>
                    </>
                </BaseModal>
            </Modal>
        </>
    )
}
