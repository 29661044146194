import { FC, ReactNode, createContext, useContext, useReducer } from 'react'

// ** Modals types

import { LensLoginPayload } from '../components/modals/lens-login'
import { ErrorPayload } from '../components/modals/error'
import { ConfirmPayload } from '@/components/modals/confirm'
import { NotConnectedPayload } from '@/components/modals/not-connected'
import { CommentPayload } from '@/components/modals/comment'
import { CollectPayload } from '@/components/modals/collect'

/**
 * Modals types
 *
 * @dev Add a new modal type here
 * @dev Note: the modal type must be in camelCase.
 */
const modalTypes = [
  'handleTx',
  'notConnected',
  'error',
  'lensLogin',
  'signInProcess',
  'lensEnableManager',
  'confirm',
  'comment',
  'collect',
  'connectWallet',
] as const

/**
 * Type of the modals payloads
 *
 * @dev Add a new modal payload type here
 * @dev If a modal doesn't have a payload, its set to `unknown`
 *
 * @dev These payload are static, once the modal is open, they don't update.
 */
type ModalPayloads = {
  lensLogin: LensLoginPayload
  error: ErrorPayload
  confirm: ConfirmPayload
  notConnected: NotConnectedPayload
  comment: CommentPayload
  collect: CollectPayload
}

/**
 * Type of the modal types
 */
type ModalType = (typeof modalTypes)[number]

/**
 * Type of the modal payload
 */
type ModalPayload<T extends ModalType> = T extends keyof ModalPayloads
  ? ModalPayloads[T] | undefined
  : unknown

/*************************************************
 *                Modals State                   *
 *************************************************/

/**
 * Context to store the modals state
 */
const ModalsStateContext = createContext<ModalsStateContextType>(
  {} as ModalsStateContextType
)

/**
 * Type of the modals state
 */
type ModalsStateContextType = {
  [K in ModalType]: { isOpen: boolean; data: ModalPayload<K> }
}

/**
 * Initial state of the modals
 */
const initialState: ModalsStateContextType = {
  ...modalTypes.reduce(
    (acc, type) => ({ ...acc, [type]: { isOpen: false, data: null } }),
    {} as ModalsStateContextType
  ),
}

/**
 * Type of the action to update the modals state
 */
type Action<T extends ModalType> = { type: T; payload: ModalPayload<T> }

/**
 * Reducer to update the modals state
 */
function reducer(state: typeof initialState, action: Action<ModalType>) {
  const modalKey = action.type
  if (modalKey) {
    return { ...state, [modalKey]: action.payload }
  }
  return state
}

/*************************************************
 *                Modals Actions                 *
 *************************************************/

/**
 * Context to store the modals actions
 */
const ModalsActionsContext = createContext<{
  open: <T extends ModalType>(type: T, payload?: ModalPayload<T>) => void
  close: (type: ModalType) => void
  reset: () => void
}>({
  open: <T extends ModalType>(type: T, payload?: ModalPayload<T>) => {},
  close: (type: ModalType) => {},
  reset: () => {},
})

/*************************************************
 *                Modals Provider                 *
 *************************************************/

type Props = Record<'children', ReactNode>

const ModalsProvider: FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const open = <T extends ModalType>(type: T, payload?: ModalPayload<T>) => {
    dispatch({ type, payload: { isOpen: true, data: payload } })
  }

  const close = (type: ModalType) => {
    dispatch({ type, payload: { isOpen: false, data: null } })
  }

  const reset = () => {
    modalTypes.forEach((type) => {
      dispatch({ type, payload: { isOpen: false, data: null } })
    })
  }

  return (
    <ModalsStateContext.Provider value={state}>
      <ModalsActionsContext.Provider value={{ open, close, reset }}>
        {children}
      </ModalsActionsContext.Provider>
    </ModalsStateContext.Provider>
  )
}

/*************************************************
 *                 Modals Hooks                  *
 *************************************************/

/**
 * Hook to get the modals state.
 *
 * This hook returns the current state of all modals. The state is an object
 * where each key is a modal type and the value is an object with `isOpen` and `data` properties.
 *
 */
function useModalsState(): ModalsStateContextType {
  return useContext(ModalsStateContext) as ModalsStateContextType
}

/**
 * Hook to use the modals actions
 *
 * This hook returns an object with `open` and `close` methods that you can use to
 * open and close modals. The `open` method takes a modal type and a payload (optional),
 * and the `close` method takes a modal type.
 */
function useModalsActions(): {
  open: <T extends ModalType>(type: T, payload?: ModalPayload<T>) => void
  close: (type: ModalType) => void
  reset: () => void
} {
  return useContext(ModalsActionsContext)
}

export { useModalsState, useModalsActions, ModalsProvider }
