// ** MUI Imports
import { Button } from '@mui/material'

// ** Hooks
import { useSession } from '@/context/session'
import { useModalsActions } from '@/context/modals'

// ** Types
import { BaseMUIButtonProps, SignInStatus } from '@/types/custom'
import { getSignInStatus } from '@/utils/helpers'

type Props = BaseMUIButtonProps & {
 /**
  * The text to be displayed inside the button.
  * @default "Sign In"
  */
 label?: string
 /**
  * Callback fired before the subscription takes place.
  */
 beforeOpen?: () => void
}

export const SignInBtn = ({
 variant = 'text',
 color = 'primary',
 size = 'medium',
 label = 'Sign In',
 beforeOpen
}: Props) => {
 const { open: openModal } = useModalsActions()

 const signInStatus = getSignInStatus()
  if (signInStatus === SignInStatus.completed) {
    return null
  }

 const logInWithLens = () => {
  openModal('signInProcess')
 }

 return (
  <Button
   variant={variant}
   color={color}
   size={size}
   onClick={() => {
    beforeOpen?.()
    logInWithLens()
   }}>
   {label}
  </Button>
 )
}
