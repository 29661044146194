// ** Mui Imports
import {
  Modal,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { IconX } from '@tabler/icons-react'

// ** Hooks
import { useModalsActions, useModalsState } from '@/context/modals'

// ** Components
import BaseModal from './base'
import { WalletOptions } from '../ctaButtons/wallet/LoginWallet'

export const ConnectWalletModal = () => {
  const theme = useTheme()
  const isSmScreen = useMediaQuery(theme.breakpoints.down('md'))
  const { connectWallet } = useModalsState()
  const { isOpen } = connectWallet
  const { close } = useModalsActions()

  const handleCloseModal = () => {
    close('connectWallet')
  }

  return (
    <>
      <Modal open={isOpen} onClose={handleCloseModal}>
        <BaseModal
          title="Login"
          action={
            <IconX onClick={handleCloseModal} style={{ cursor: 'pointer' }} />
          }
          sx={{
            minHeight: '10rem',
            justifyContent: 'center',
          }}
        >
          <>
            <Stack
              width={'100%'}
              direction={'column'}
              alignItems={'center'}
              justifyContent={'center'}
              gap={0.5}
              sx={{
                my: 2,
              }}
            >
              <Typography variant="h6" component="h2" textAlign={'center'}>
                Connect your wallet.
              </Typography>
              <Typography variant="body1" textAlign={'center'}>
                Connect with one of our available wallet providers.
              </Typography>
            </Stack>
            <Stack
              direction={'column'}
              gap={2}
              maxHeight={isSmScreen ? '10rem' : '15rem'}
              sx={{
                overflowY: 'auto',
              }}
            >
              <WalletOptions />
            </Stack>
          </>
        </BaseModal>
      </Modal>
    </>
  )
}
