export const APP_ID_HANDLEFINDER = 'HandleFinder'

export const RATIO_NAME = {
  none: {
    Global: '',
    ScoreAdjusted: '',
  },
  Likes: {
    Global: 'Total likes',
    ScoreAdjusted: 'Score multiplied likes',
  },
  Followers: {
    Global: 'Total followers',
    ScoreAdjusted: 'Score multiplied followers',
  },
  Collects: {
    Global: 'Total collects',
    ScoreAdjusted: 'Score multiplied collects',
  },
  Growth: {
    Global: 'Growth in followers',
    ScoreAdjusted: 'Score multiplied growth',
  },
  RecentlyCreated: {
    Global: 'Total score',
    ScoreAdjusted: 'Score multiplied score',
  },
}

// Metamask
export const USER_REJECTED_CODE_ERROR = 4001
