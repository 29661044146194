import {
  IconButton,
  Box,
  AppBar,
  useMediaQuery,
  Toolbar,
  styled,
  Stack,
  Skeleton,
} from '@mui/material'
import { useSelector, useDispatch } from '../../../../store/Store'
import {
  toggleSidebar,
  toggleMobileSidebar,
} from '../../../../store/customizer/CustomizerSlice'
import { IconMenu2 } from '@tabler/icons-react'
import Notifications from './Notification'
import Profile from './Profile'
import Cart from './Cart'
import Search from './Search'
import Language from './Language'
import { AppState } from '../../../../store/Store'
import Navigation from './Navigation'
import MobileRightSidebar from './MobileRightSidebar'
import { useSession } from '../../../../context/session'
import { LoginWallet } from '../../../../components/ctaButtons/wallet/LoginWallet'

const Header = () => {
  const lgUp = useMediaQuery((theme: any) => theme.breakpoints.up('lg'))

  // drawer
  const customizer = useSelector((state: AppState) => state.customizer)
  const dispatch = useDispatch()
  const { notLoggedIn, loggedIn, isLoadingSignIn, connectedNotLogged } =
    useSession()

  const AppBarStyled = styled(AppBar)(({ theme }) => ({
    boxShadow: 'none',
    background: theme.palette.background.paper,
    justifyContent: 'center',
    backdropFilter: 'blur(4px)',
    [theme.breakpoints.up('lg')]: {
      minHeight: customizer.TopbarHeight,
    },
  }))
  const ToolbarStyled = styled(Toolbar)(({ theme }) => ({
    width: '100%',
    color: theme.palette.text.secondary,
  }))

  return (
    <AppBarStyled position="sticky" color="default">
      <ToolbarStyled>
        {/* ------------------------------------------- */}
        {/* Toggle Button Sidebar */}
        {/* ------------------------------------------- */}
        {!lgUp && (
          <IconButton
            color="inherit"
            aria-label="menu"
            onClick={
              lgUp
                ? () => dispatch(toggleSidebar())
                : () => dispatch(toggleMobileSidebar())
            }
          >
            <IconMenu2 size="20" />
          </IconButton>
        )}

        <Search />
        <Box flexGrow={1} />
        {/* ------------------------------------------- */}
        {/* Wallet */}
        {/* ------------------------------------------- */}
        {isLoadingSignIn && (
          <Skeleton variant="circular" width={40} height={40} />
        )}
        {loggedIn && (
          <>
            <Stack spacing={1} direction="row" alignItems="center">
              <Profile />
            </Stack>
          </>
        )}
        {notLoggedIn && (
          <LoginWallet variant={undefined} color="secondary" size={undefined} />
        )}
        {connectedNotLogged && (
          <>
            <Stack spacing={1} direction="row" alignItems="center">
              <Profile />
            </Stack>
          </>
        )}
      </ToolbarStyled>
    </AppBarStyled>
  )
}

export default Header
