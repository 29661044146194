import { styled, Container, Box, useTheme } from '@mui/material'
import { useSelector } from '../../store/Store'
import { AppState } from '../../store/Store'
import Header from './vertical/header/Header'
import Sidebar from './vertical/sidebar/Sidebar'
import Navigation from '../full/horizontal/navbar/Navigation'
import HorizontalHeader from '../full/horizontal/header/Header'
import { ConfirmModal } from '@/components/modals/confirm'
import { ErrorModal } from '@/components/modals/error'
import { useAccount } from 'wagmi'
import { useModalsState } from '@/context/modals'
import { SignInProcessModal } from '@/components/modals/sign-in'
import { useSession } from '@/context/session'
import { CommentModal } from '@/components/modals/comment'
import { NotConnectedModal } from '@/components/modals/not-connected'
import { CollectModal } from '@/components/modals/collect'
import { ConnectWalletModal } from '@/components/modals/connectWallet'

const MainWrapper = styled('div')(() => ({
  display: 'flex',
  minHeight: '100vh',
  width: '100%',
}))

const PageWrapper = styled('div')(() => ({
  display: 'flex',
  flexGrow: 1,
  paddingBottom: '60px',
  flexDirection: 'column',
  zIndex: 1,
  width: '100%',
  backgroundColor: 'transparent',
}))

interface Props {
  children: React.ReactNode
}

// const FullLayout: FC = ({children}) => {
const FullLayout: React.FC<Props> = ({ children }) => {
  const customizer = useSelector((state: AppState) => state.customizer)
  const theme = useTheme()
  const { isConnected } = useAccount()
  const { signInProcess } = useModalsState()
  const { isLoadingSignIn } = useSession()

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <MainWrapper>
        {/* ------------------------------------------- */}
        {/* Sidebar */}
        {/* ------------------------------------------- */}
        {customizer.isHorizontal ? '' : <Sidebar />}
        {/* ------------------------------------------- */}
        {/* Main Wrapper */}
        {/* ------------------------------------------- */}
        <PageWrapper
          className="page-wrapper"
          sx={{
            ...(customizer.isCollapse && {
              [theme.breakpoints.up('lg')]: {
                ml: `${customizer.MiniSidebarWidth}px`,
              },
            }),
          }}
        >
          {/* ------------------------------------------- */}
          {/* Header */}
          {/* ------------------------------------------- */}
          {customizer.isHorizontal ? <HorizontalHeader /> : <Header />}
          {/* PageContent */}
          {customizer.isHorizontal ? <Navigation /> : ''}
          <Container
            sx={{
              maxWidth: '100%!important',
            }}
          >
            {/* ------------------------------------------- */}
            {/* PageContent */}
            {/* ------------------------------------------- */}

            <Box sx={{ minHeight: 'calc(100vh - 170px)' }}>
              {/* <Outlet /> */}
              {children}
              {/* <Index /> */}
            </Box>

            {/* ------------------------------------------- */}
            {/* End Page */}
            {/* ------------------------------------------- */}
          </Container>
        </PageWrapper>
      </MainWrapper>

      {/* ------------------------------------------- */}
      {/* Modals */}
      {/* ------------------------------------------- */}
      <ConfirmModal />
      <ErrorModal />
      <NotConnectedModal />
      <CommentModal />
      <CollectModal />
      <ConnectWalletModal />
      {isConnected && signInProcess.isOpen && <SignInProcessModal />}
    </Box>
  )
}

export default FullLayout
