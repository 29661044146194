import { useTheme } from '@mui/material/styles';
import { CardContent, Box, Divider, Theme, SxProps, CardHeader } from '@mui/material';
import { useSelector } from '../../../src/store/Store';
import { AppState } from '../../../src/store/Store';

type Props = {
  title?: string;
  subtitle?: string;
  action?: JSX.Element | any;
  footer?: JSX.Element;
  children?: JSX.Element;
  sx?: SxProps<Theme> | undefined;
};

const BaseModal = ({
  title,
  subtitle,
  children,
  action,
  footer,
  sx: sxProps,
}: Props) => {
  const customizer = useSelector((state: AppState) => state.customizer);

  const theme = useTheme();
  const borderColor = theme.palette.divider;

  const modalStyle: SxProps<Theme> = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "80vh",
    minHeight: "10rem",
    minWidth: "20rem",
    width: { md: 600 },
    overflow: "auto",
    border: !customizer.isCardShadow ? `1px solid ${borderColor}` : 'none',
    borderRadius: "1rem",
    bgcolor: theme.palette.background.paper,
    padding: 1,
    boxShadow: 24,
    display: "flex",
    flexDirection: "column",
  };

  return (
    <Box
      sx={sxProps ? { ...modalStyle, ...sxProps } : modalStyle}
    >
      <CardHeader
        title={title}
        subheader={subtitle}
        action={action}
        sx={{
          width: "100%",
          justifyContent: "space-between"
        }}
      />

      {title || subtitle ? (
        <Divider sx={{ width: "100%", my: 0.1 }} />
      ) : (
        ""
      )}

      <CardContent sx={{ paddingX: "1.5rem", paddingTop: "0" }}>
        {children}
      </CardContent>

      {footer ? (
        <>
          <Divider sx={{ width: "100%", my: 0.1 }} />
          <Box p={3}>{footer}</Box>
        </>
      ) : (
        ""
      )}
    </Box>
  );
};

export default BaseModal;
