// ** React Imports
import { useEffect, useMemo } from 'react'

// ** MUI Imports
import { Box, Divider, Modal, Stack, Typography } from '@mui/material'
import { IconX } from '@tabler/icons-react'

// ** Hooks Imports
import { useSession } from '@/context/session'
import { useModalsActions, useModalsState } from '@/context/modals'

// ** Components
import { LoginWallet } from '../ctaButtons/wallet/LoginWallet'
import { SignInBtn } from '../ctaButtons/wallet/SignInWallet'
import BaseModal from './base'

export enum NotConnectedCase {
 NotConnected = 'not-connected',
 PendingLogIn = 'pending-login'
}

export type NotConnectedPayload = {
 modalCase: NotConnectedCase
}

export const NotConnectedModal = () => {
 const { notLoggedIn, connectedNotLogged } = useSession()
 const { notConnected } = useModalsState()
 const { close } = useModalsActions()

 const handleCloseModal = () => close('notConnected')

 const completed = useMemo(() => {
  if (!notConnected.data) return false
  const { modalCase } = notConnected.data
  const notLoggedCompleted =
   modalCase === NotConnectedCase.NotConnected && !notLoggedIn
  const pendingCompleted =
   modalCase === NotConnectedCase.PendingLogIn && !connectedNotLogged
  return notLoggedCompleted || pendingCompleted
 }, [notConnected, notLoggedIn, connectedNotLogged])

 useEffect(() => {
  if (completed) handleCloseModal()
 }, [completed])

 if (!notConnected.data) return null
 const { modalCase } = notConnected.data

 const isPendingLogin = modalCase === NotConnectedCase.PendingLogIn

 return (
  <>
   <Modal
    open={notConnected.isOpen}
    onClose={handleCloseModal}>
    <BaseModal
     action={
      <IconX
       onClick={handleCloseModal}
       style={{ cursor: 'pointer' }}
      />
     }
     sx={{
      minHeight: '10rem',
      minWidth: '15rem'
     }}>
     <Stack
      direction={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      gap={1}>
      <Typography
       variant='h5'
       textAlign={'center'}
       width={'100%'}>
       {isPendingLogin ? 'Pending login' : 'Not connected'}
      </Typography>
      <Divider />
      <Typography
       variant='body1'
       textAlign={'center'}>
       {isPendingLogin
        ? 'You should complete the login process to continue.'
        : 'You should connect your wallet to get a better experience.'}
      </Typography>
      <Divider />
      <Box
       sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        mt: 1
       }}>
       {isPendingLogin ? (
        <SignInBtn
         variant='contained'
         color={undefined}
         size='large'
         label='Complete login'
         beforeOpen={() => close('notConnected')}
        />
       ) : (
        <LoginWallet
         variant='contained'
         size='large'
         label='Connect'
         beforeOpen={() => close('notConnected')}
        />
       )}
      </Box>
     </Stack>
    </BaseModal>
   </Modal>
  </>
 )
}
