// ** React & MUI
import { useState } from 'react'
import {
  Modal,
  Button,
  Stack,
  TextField,
  CircularProgress,
} from '@mui/material'

// ** Components
import BaseModal from './base'

// ** Context
import { useModalsActions, useModalsState } from '@/context/modals'
import { useLens } from '@/context/lens'

/**
 * Payload for the Comment modal
 * @param publicationId - The publicationId where the comment will be created.
 */
export interface CommentPayload {
  publicationId: string
  isMomokaPub: boolean
}

/////////////////////////////////////
export const CommentModal = () => {
  const { close } = useModalsActions()
  const { comment: commentModal } = useModalsState()
  const { comment: commentOnLens } = useLens()

  const [comment, setComment] = useState<string>('')
  const [submitting, setSubmitting] = useState<boolean>(false)

  if (!commentModal.data) return null
  const { publicationId, isMomokaPub } = commentModal.data

  const handleCloseModal = () => {
    close('comment')
    setComment('')
  }

  const handleConfirmClick = async () => {
    setSubmitting(true)
    await handleSubmit()
  }

  const handleSubmit = async () => {
    try {
      await commentOnLens(publicationId, comment, isMomokaPub)
      handleCloseModal()
    } catch (err) {
      console.log(err)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <>
      <Modal open={commentModal.isOpen} onClose={handleCloseModal}>
        <BaseModal sx={{ justifyContent: 'center' }}>
          <Stack spacing={2}>
            <TextField
              id="outlined-multiline-static"
              placeholder="Share your thoughts"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              multiline
              fullWidth
              rows={4}
            />
            <Stack
              display="flex"
              flexDirection="row"
              justifyContent="center"
              gap={2}
            >
              <Button
                color="success"
                size="large"
                disabled={submitting}
                onClick={handleConfirmClick}
              >
                {!submitting ? 'Post' : <CircularProgress />}
              </Button>
              <Button color="error" size="large" onClick={handleCloseModal}>
                Cancel
              </Button>
            </Stack>
          </Stack>
        </BaseModal>
      </Modal>
    </>
  )
}
