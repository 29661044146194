import { useMediaQuery, Box, Drawer, useTheme } from '@mui/material'
import SidebarItems from './SidebarItems'
import Logo from '../../shared/logo/Logo'
import { useSelector, useDispatch } from '../../../../store/Store'
import {
 hoverSidebar,
 toggleMobileSidebar
} from '../../../../store/customizer/CustomizerSlice'
import Scrollbar from '../../../../components/custom-scroll/Scrollbar'
import { Profile } from './SidebarProfile/Profile'
import { AppState } from '../../../../store/Store'

const Sidebar = () => {
 const lgUp = useMediaQuery((theme: any) => theme.breakpoints.up('lg'))
 const customizer = useSelector((state: AppState) => state.customizer)
 const dispatch = useDispatch()
 const theme = useTheme()
 const toggleWidth =
  customizer.isCollapse && !customizer.isSidebarHover
   ? customizer.MiniSidebarWidth
   : customizer.SidebarWidth

 const onHoverEnter = () => {
  if (customizer.isCollapse) {
   dispatch(hoverSidebar(true))
  }
 }

 const onHoverLeave = () => {
  dispatch(hoverSidebar(false))
 }

 if (lgUp) {
  return (
   <Box
    sx={{
     width: toggleWidth,
     flexShrink: 0,
     ...(customizer.isCollapse && {
      position: 'absolute'
     })
    }}>
    {/* ------------------------------------------- */}
    {/* Sidebar for desktop */}
    {/* ------------------------------------------- */}
    <Drawer
     anchor='left'
     open
     onMouseEnter={onHoverEnter}
     onMouseLeave={onHoverLeave}
     variant='permanent'
     PaperProps={{
      sx: {
       transition: theme.transitions.create('width', {
        duration: theme.transitions.duration.shortest
       }),
       width: toggleWidth,
       boxSizing: 'border-box'
      }
     }}>
     {/* ------------------------------------------- */}
     {/* Sidebar Box */}
     {/* ------------------------------------------- */}
     <Box
      sx={{
       height: '100%'
      }}>
      {/* ------------------------------------------- */}
      {/* Logo */}
      {/* ------------------------------------------- */}
      <Box
       px={1.8}
       paddingTop={1}>
       <Logo />
      </Box>
      <Scrollbar sx={{ height: 'calc(100% - 190px)' }}>
       {/* ------------------------------------------- */}
       {/* Sidebar Items */}
       {/* ------------------------------------------- */}
       <SidebarItems />
      </Scrollbar>
      <Profile />
     </Box>
    </Drawer>
   </Box>
  )
 }

 return (
  <Drawer
   anchor='left'
   open={customizer.isMobileSidebar}
   onClose={() => dispatch(toggleMobileSidebar())}
   variant='temporary'
   PaperProps={{
    sx: {
     width: customizer.SidebarWidth,
     border: '0 !important',
     boxShadow: (theme) => theme.shadows[8]
    }
   }}>
   {/* ------------------------------------------- */}
   {/* Logo */}
   {/* ------------------------------------------- */}
   <Box
    px={2}
    paddingTop={1}>
    <Logo />
   </Box>
   {/* ------------------------------------------- */}
   {/* Sidebar For Mobile */}
   {/* ------------------------------------------- */}
   <SidebarItems />
  </Drawer>
 )
}

export default Sidebar
