import { SxProps, Theme } from '@mui/material'
import { UserSession } from './apps/users'

export type Months =
 | 'January'
 | 'February'
 | 'March'
 | 'April'
 | 'May'
 | 'June'
 | 'July'
 | 'August'
 | 'September'
 | 'October'
 | 'November'
 | 'December'

export enum UserSessionStatus {
 Logged = 'logged-in',
 ConnectedNotLogged = 'connected-not-logged-in',
 NotLogged = 'not-logged',
 Loading = 'loading'
}

export type ProjectModeType = 'production' | 'development'

// export type SignInStatus = 'completed' | 'pending' | 'loading' | undefined

/**
 * Sign in status
 * @description The status of the sign-in process.
 *
 * - undefined: default status.
 * - "pending": remains signature or something to complete.
 * - "loading": waiting for an internal response.
 * - "completed": sign-in process completed successfully.
 * - "rejected": user has denied the sign-in process
 */
export enum SignInStatus {
    pending = "pending",
    loading = "loading",
    completed = "completed",
    rejected = "rejected"
}

export type UserProfileData = Omit<UserSession, 'address'>

export type BaseMUIButtonProps = {
 /**
  * The variant to use.
  * @default "text"
  */
 variant?: 'text' | 'outlined' | 'contained' | undefined
 /**
  * The color of the component.
  * It supports both default and custom theme colors, which can be added as shown in the
  * [palette customization guide](https://mui.com/material-ui/customization/palette/#adding-new-colors).
  * @default "primary"
  */
 color?:
  | 'primary'
  | 'secondary'
  | 'error'
  | 'success'
  | 'info'
  | 'warning'
  | 'inherit'
  | undefined
 /**
  * The size of the component.
  * `small` is equivalent to the dense button styling.
  * @default "medium"
  */
 size?: 'small' | 'medium' | 'large' | undefined
 /**
  * If `true`, the button will take up the full width of its container.
  * @default false
  */
 fullWidth?: boolean
 /**
  * If true, the button will be disabled
  * @default false
  */
 disabled?: boolean
 /**
  * The system prop that allows defining system overrides as well as additional CSS styles.
  */
 sx?: SxProps<Theme>
}
