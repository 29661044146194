// ** React Imports
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

// ** Lens Imports
import {
  AnyPublicationFragment,
  ExploreProfilesOrderByType,
  LensClient,
  PaginatedResult,
  production,
  ProfileFragment,
  PublicationReactionType,
  isRelaySuccess,
  LensTransactionStatusType,
  SimpleCollectOpenActionSettingsFragment,
  MultirecipientFeeCollectOpenActionSettingsFragment,
  PostFragment,
  PublicationType,
  ExplorePublicationsOrderByType,
  ChangeProfileManagerActionType,
} from '@lens-protocol/client'
import { textOnly } from '@lens-protocol/metadata'

// ** Hooks
import { useAccount, useSignMessage, useSignTypedData } from 'wagmi'
import { erc20Abi, TypedDataDomain } from 'viem'
import { Contract, parseEther, verifyMessage } from 'ethers'
import { useIPFSStorage } from './IPFSStorage'
import { useHandleNetwork } from './network'
import { ApolloClient, InMemoryCache } from '@apollo/client'
import { useEthersSigner } from '@/hooks/useEthersAdapters'

// ** Utils
import { UserSession, defaultUserSession } from '../types/apps/users'
import { LENS_API_URL } from '@/utils/constants/api'
import {
  formatLensFragmentData,
  storeCredentials,
  wipeCredentials,
  loadCredentials,
  getLensCrendentials,
  updateLensCrendentials,
} from '../utils/helpers'
import { LensAuth } from '../utils/types'
import { CredentialType, LensMutationRefresh } from '@/types/auth/auth'
import {
  EXPLORE_PUBLICATIONS_QUERY,
  REFRESH_LENS_ACCESS_TOKEN,
  VERIFY_JWT,
} from '@/utils/constants/query'
import { APP_ID_HANDLEFINDER } from '@/utils/constants/literals'

import {
  ExplorePublicationsResponse,
  ExplorePublicationsVariables,
} from '@/utils/constants/types'
import { isProductionMode } from '@/utils'

/**
 * Locas storage provider.
 * @dev This handles Lens authentication, even after page refresh.
 */
class LocalStorageProvider {
  getItem(key: string): Promise<string | null> {
    const value = window.localStorage.getItem(key)
    return Promise.resolve(value)
  }

  setItem(key: string, value: string): Promise<void> {
    window.localStorage.setItem(key, value)
    return Promise.resolve()
  }

  removeItem(key: string): Promise<void> {
    window.localStorage.removeItem(key)
    return Promise.resolve()
  }
}

/**
 * Lens Post Collect Module type.
 * @type CollectModule
 */
export type CollectActionModuleSettings =
  | SimpleCollectOpenActionSettingsFragment
  | MultirecipientFeeCollectOpenActionSettingsFragment

/**
 * Lens interface declaration.
 * @interface LensValue
 */
interface LensValue {
  /**
   * Lens client
   */
  client: LensClient | undefined

  isDisconnecting: boolean

  isCheckingLocalKeys: boolean
  isCheckingLensData: boolean
  user: UserSession

  handleLensDisconnecting: (value: boolean) => void

  /**
   * Access token.
   */
  auth: LensAuth | undefined

  /**
   * ID of the profile authenticated.
   */
  profileId?: string

  /**
   * Handle of the profile authenticated.
   */

  handle: string | undefined

  /**
   * Indicates if the user has a Lens handle.
   */
  hasLensHandle: boolean

  /**
   * Indicates if the logged-in profile has enabled the profile manager.
   */
  isManagerEnabled: boolean | null

  handleJWT: (
    address: string,
    message: string,
    accessToken: string,
    connectedAddress?: string
  ) => Promise<void>

  /**
   * Signs out from Lens.
   * @returns {Promise<void>} A promise that resolves when the user is signed out.
   */
  signOut: () => Promise<void>

  getProfile: (profileId: string) => Promise<ProfileFragment | null>
  getProfilesById: (
    profilesIds: string[]
  ) => Promise<PaginatedResult<ProfileFragment>>
  getProfilesByHandle: (
    handle: string
  ) => Promise<PaginatedResult<ProfileFragment>>
  getPostsById: (
    postsIds: string[]
  ) => Promise<PaginatedResult<AnyPublicationFragment>>
  getPostById: (publicationId: string) => Promise<AnyPublicationFragment | null>
  getUserPosts: (
    profileId: string
  ) => Promise<PaginatedResult<PostFragment | null>>
  getUserLatestsPosts: (
    profileId: string
  ) => Promise<PaginatedResult<AnyPublicationFragment | null>>
  getUserLatestsFollowers: (
    profileId: string
  ) => Promise<ProfileFragment[] | null>
  getUserTopFollowers: (profileId: string) => Promise<ProfileFragment[] | null>
  isFollowedByMe: (profileId: string) => Promise<any>
  getExploreProfiles: () => Promise<PaginatedResult<ProfileFragment>>

  /**
   * Check if jwt is storaged and valid
   * @returns {Promise<boolean>} A promise that resolves if jwt exists in local storage and it is valid
   */
  checkIsJWTstoraged: () => Promise<boolean>

  /**
   * Fetch managed profiles.
   * @return {Promise<PaginatedResult<ProfileFragment>>} A promise that resolves to managed profiles.
   */
  getManagedProfiles: (
    walletAddress?: `0x${string}`
  ) => Promise<PaginatedResult<ProfileFragment> | undefined>

  /**
   * Authenticate a profile.
   * @param {string | undefined} profileId - ID of the profile to be authenticated.
   * @return {Promise<LensAuth | undefined>} A promise that resolves when authentication is completed.
   */
  authenticate: (profileId?: string) => Promise<LensAuth | undefined>

  /**
   * Authenticate with refresh token
   * @returns {Promise<string>} profileId
   */
  authenticateByRefreshToken: () => Promise<string | undefined>
  follow: (profileId: string) => Promise<any>
  unfollow: (profileId: string) => Promise<any>
  like: (publicationId: string) => Promise<any>
  unlike: (publicationId: string) => Promise<any>
  comment: (
    publicationId: string,
    content: string,
    isMomokaPub: boolean
  ) => Promise<any>
  collectCriteria: (publicationId: string) => Promise<any>
  collect: (publicationId: string) => Promise<any>
  exploreOrbClubPublications: (
    clubId: string
  ) => Promise<ExplorePublicationsResponse['explorePublications']['items']>
  enableProfileManager: () => Promise<void>
}

const LensContext = createContext<LensValue>({} as LensValue)

/**
 * Lens context provider
 */
export function LensProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<UserSession>(defaultUserSession)
  const [isLoading, setIsLoading] = useState(false)
  const [isManagerEnabled, setIsManagerEnabled] = useState<boolean | null>(null)
  const [isCheckingLocalKeys, setIsCheckingLocalKeys] = useState(false)
  const [isCheckingLensData, setIsCheckingLensData] = useState(false)
  const [isDisconnecting, setIsDisconnecting] = useState(false)
  const [auth, setAuth] = useState<LensAuth>()

  const { address, isConnected } = useAccount()
  const { signMessageAsync } = useSignMessage()
  const { chainId } = useHandleNetwork()
  const { uploadJSON } = useIPFSStorage()
  const { signTypedDataAsync } = useSignTypedData()
  const signer = useEthersSigner()

  const lensClient = useMemo(() => {
    return new LensClient({
      environment: production,
      storage: new LocalStorageProvider(),
    })
  }, [])

  const apolloClient = useMemo(
    () =>
      new ApolloClient({
        cache: new InMemoryCache(),
        uri: LENS_API_URL[137 as keyof typeof LENS_API_URL],
        defaultOptions: {
          query: {
            fetchPolicy: 'no-cache',
          },
        },
      }),
    [chainId]
  )

  useEffect(() => {
    if (isDisconnecting) return

    if (address && isConnected && !auth && apolloClient && chainId) {
      checkLocalKeys()
    }
  }, [address, isConnected, auth, chainId])

  useEffect(() => {
    setIsCheckingLensData(true)
    checkLensData(user.profileId)
  }, [user, auth])

  const checkLocalKeys = async () => {
    setIsCheckingLocalKeys(true)
    try {
      if (!address) throw new Error('Address undefined.')

      const keys = loadCredentials(
        address,
        CredentialType.lens,
        isProductionMode
      )
      if (keys && 'accessToken' in keys) {
        const isVerified = await checkAccessToken(keys.accessToken)

        if (isVerified) {
          await handleJWT(address, keys.message, keys.accessToken, address)
          setAuth(keys)
          return keys
        }

        const lensValue = getLensCrendentials()
        if (lensValue) {
          const refreshToken = lensValue.data.refreshToken
          const data = await refreshAccessToken(refreshToken)
          if (data) {
            console.log(`Lens accessToken refreshed successfully!.`)
            const newKeys: LensAuth = {
              accessToken: data.accessToken,
              message: keys.message,
            }
            storeCredentials(
              address,
              CredentialType.lens,
              newKeys,
              isProductionMode
            )
            updateLensCrendentials(data.refreshToken)
            await handleJWT(address, keys.message, data.accessToken, address)
            setAuth(newKeys)
            return newKeys
          }
        }
        wipeCredentials(address, CredentialType.lens, isProductionMode)
      }
    } catch (error) {
      console.log('Error checking lens local keys: ', error)
    } finally {
      setIsCheckingLocalKeys(false)
    }
  }

  /**
   * Get profile data by profileId
   */
  const getProfileByProfileId = async (profileId: string) => {
    return await lensClient.profile.fetch({
      forProfileId: profileId,
    })
  }

  const handleProfileId = async (id?: string) => {
    if (id) {
      const profile = await getLensDataByProfileId(id)
      if (profile) {
        setUser({
          ...profile,
          address: address,
        })
        return
      }
    }
    setUser(defaultUserSession)
  }

  /**
   * Fetch managed profiles.
   */
  const getManagedProfiles = async (walletAddress?: `0x${string}`) => {
    if (!walletAddress) return
    return await lensClient.wallet.profilesManaged({ for: walletAddress })
  }

  /**
   * Get the profileId.
   * @returns {Promise<string | null>} A promise that returns the profileId used to get Lens handle.
   */
  const getProfileId = async (): Promise<string | null> => {
    const isAuth = await lensClient.authentication.isAuthenticated()
    if (!isAuth) return null
    return await lensClient.authentication.getProfileId()
  }

  const getLensDataByProfileId = async (profileId: string) => {
    try {
      const lensData = await getProfileByProfileId(profileId)
      return formatLensFragmentData(lensData)
    } catch (error) {
      console.log('Error getting lens data by profileId: ', error)
    }
  }

  const getProfile = async (forProfileId: string) => {
    return lensClient.profile.fetch({
      forProfileId,
    })
  }

  const getExploreProfiles = async () => {
    return lensClient.explore.profiles({
      orderBy: ExploreProfilesOrderByType.MostFollowers,
    })
  }

  const getProfilesById = async (profilesIds: string[]) => {
    return await lensClient.profile.fetchAll({
      where: { profileIds: profilesIds },
    })
  }

  const getProfilesByHandle = async (handle: string) => {
    return await lensClient.search.profiles({ query: handle })
  }

  const getPostsById = async (postIds: string[]) => {
    return await lensClient.publication.fetchAll({
      where: { publicationIds: postIds },
    })
  }

  const getPostById = async (publicationId: string) => {
    return await lensClient.publication.fetch({
      forId: publicationId,
    })
  }

  const getUserPosts = async (profileId: string) => {
    return await lensClient.publication.fetchAll({
      where: { from: [profileId], publicationTypes: [PublicationType.Post] },
    })
  }

  const getUserLatestsPosts = async (profileId: string) => {
    let recentPosts: AnyPublicationFragment[] = []
    let pageInfo = null

    const oneWeekAgo = new Date()
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7)

    do {
      const res = await lensClient.publication.fetchAll({
        where: { from: [profileId], publicationTypes: [PublicationType.Post] },
        cursor: pageInfo?.next,
      })

      if (!res.pageInfo) throw new Error('no pagination provided')

      const recentPagePosts = res.items.filter((post) => {
        const createdAt = new Date(post.createdAt)
        return createdAt >= oneWeekAgo
      })

      recentPosts = [...recentPosts, ...recentPagePosts]

      if (res.items.length === 0) {
        break
      }

      const lastPostCreatedAt = new Date(
        res.items[res.items.length - 1].createdAt
      )

      if (lastPostCreatedAt < oneWeekAgo) {
        break
      }

      pageInfo = res.pageInfo
    } while (pageInfo.next)

    return recentPosts
  }

  /**
   * Fetch the latest followers of a given profileId.
   * @returns {Promise<user[]>} A promise that resolves with the latests user followers.
   */

  const getUserLatestsFollowers = async (profileId: string) => {
    const res = await lensClient.profile.followers({ of: profileId })

    const oneWeekAgo = new Date()
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7)

    const recentFollows = res.items.filter((post) => {
      const createdAt = new Date(post.createdAt)
      return createdAt >= oneWeekAgo
    })

    return recentFollows
  }

  /**
   * Fetch if the logged user is followowing the given profileId.
   * @returns {Promise<any>} A promise that resolves if the logged user is a follower.
   */

  const isFollowedByMe = async (profileId: string) => {
    if (!user.profileId) return
    const res = await lensClient.profile.followStatusBulk({
      followInfos: [{ follower: user.profileId, profileId: profileId }],
    })
    return res
  }

  /**
   * Fetch the top follower for a given profileId.
   * @returns {Promise<user[]>} A promise that resolves with the top followers of the user.
   */

  const getUserTopFollowers = async (profileId: string) => {
    try {
      let followers: any = []
      let pageInfo = null

      do {
        const res = await lensClient.profile.followers({
          of: profileId,
          cursor: pageInfo?.next,
        })

        if (!res.pageInfo) throw new Error('no pagination provided')

        followers = [...followers, ...res.items]

        pageInfo = res.pageInfo
      } while (pageInfo.next)

      const topFollowers = followers
        .sort((a, b) => b.stats.totalFollowers - a.stats.totalFollowers)
        .slice(0, 10)

      return topFollowers
    } catch (error) {
      console.error('Error fetching followers:', error)
      return []
    }
  }

  const checkIsJWTstoraged = async (): Promise<boolean> => {
    const jwt = localStorage.getItem('jwt')

    if (!jwt) {
      return false
    }

    return true
  }

  const handleJWT = async (
    address: string,
    message: string,
    accessToken: string,
    connectedAddress?: string
  ) => {
    const lowerAddress = address.toLowerCase()
    let lowerConnected = connectedAddress?.toLowerCase()

    if (!connectedAddress) {
      const localAddress = localStorage.getItem('address')
      const connectedLocalAddress = localAddress
        ? JSON.parse(localAddress)
        : null
      if (!connectedLocalAddress) {
        console.log('connectedAddress not found.')
        return
      }
      lowerConnected = connectedLocalAddress.toLowerCase()
    }

    if (lowerAddress !== lowerConnected) {
      localStorage.setItem('address', JSON.stringify(null))
      return
    }

    localStorage.setItem('jwt', 'base JWT')
    localStorage.setItem('accessToken', accessToken)
  }

  const checkLensData = async (profileId?: string) => {
    if (!auth) return

    if (!profileId) {
      const lensProfileId = await getProfileId()
      if (lensProfileId) {
        await handleProfileId(lensProfileId)
        await checkProfileManager(lensProfileId)
      }
    } else {
      await checkProfileManager(profileId)
    }
    setIsCheckingLensData(false)
  }

  const handleDisconnecting = (value: boolean) => setIsDisconnecting(value)

  const checkAccessToken = async (accessToken: string) => {
    try {
      const { data, error } = await apolloClient.query({
        query: VERIFY_JWT,
        variables: { token: accessToken },
      })

      if (error) throw new Error(error.message)

      return data.verify as boolean
    } catch (error) {
      console.log('Error querying lens API checking access token: ', error)
    }
  }

  const refreshAccessToken = async (refreshToken: string) => {
    try {
      if (!apolloClient) throw new Error('Invalid Apollo client')
      const { data, errors } = await apolloClient.mutate({
        mutation: REFRESH_LENS_ACCESS_TOKEN,
        variables: { refreshToken: refreshToken },
      })
      if (errors && errors.length) throw new Error(errors[0].message)

      return data.refresh as LensMutationRefresh
    } catch (error) {
      console.log('Error at refresh lens access token mutation: ', error)
    }
  }

  /**
   * Authenticate a profile.
   * @param {string | undefined} profileId - ID of the profile to be authenticated.
   */
  const authenticate = async (profileId?: string) => {
    try {
      if (!address) throw new Error('Address undefined.')

      const { id, text } = await lensClient.authentication.generateChallenge({
        signedBy: address,
        for: profileId,
      })

      const signature = await signMessageAsync({
        message: text,
        account: address as `0x${string}`,
      })
      if (!signature) throw new Error('No signature provided')
      await lensClient.authentication.authenticate({ id, signature })

      const accessTokenResult = await getAccessToken()

      const recoveredAddress = verifyMessage(text || '', signature)
      if (recoveredAddress.toLowerCase() !== address.toLowerCase()) {
        throw new Error('Invalid signature')
      }

      if (profileId) await handleProfileId(profileId)
      const lensAuth = {
        accessToken: accessTokenResult,
        message: text,
      }

      await handleJWT(address, lensAuth.message, lensAuth.accessToken, address)

      storeCredentials(address, CredentialType.lens, lensAuth, isProductionMode)

      setAuth(lensAuth)
      return lensAuth as LensAuth
    } catch (error) {
      console.log('Error authenticating with lens: ', error)
    }
  }

  const authenticateByRefreshToken = async (): Promise<string> => {
    let profileId = ''

    profileId = (await getProfileId()) as string

    if (profileId) {
      await handleProfileId(profileId)
      await checkProfileManager(profileId)
    }

    return profileId
  }

  /**
   * Checks if the profile manager is enabled.
   * @returns {Promise<boolean>} A promise that resolves to true if the profile manager is enabled.
   */
  const checkProfileManager = async (profileId: string) => {
    try {
      if (!profileId) throw new Error('a `profileId` must be provided.')

      const profile = await lensClient.profile.fetch({
        forProfileId: profileId,
      })
      const enabled = profile?.signless

      setIsManagerEnabled(Boolean(enabled))
    } catch (error) {
      console.log('Error checking profile manager: ', error)
    }
  }

  /**
   * Gets the access token.
   * @returns {Promise<string>} A promise that resolves to the access token.
   */
  const getAccessToken = async () => {
    const accessTokenResult = await lensClient.authentication.getAccessToken()
    const accessToken = accessTokenResult.unwrap()
    return accessToken
  }

  const enableProfileManager = async () => {
    if (!address) return

    const typedDataResult =
      await lensClient.profile.createChangeProfileManagersTypedData({
        approveSignless: true,
        changeManagers: [
          {
            action: ChangeProfileManagerActionType.Add,
            address: address,
          },
        ],
      })

    const { id, typedData } = typedDataResult.unwrap()

    const signed = await signTypedDataAsync({
      primaryType: 'ChangeDelegatedExecutorsConfig',
      domain: typedData.domain as TypedDataDomain,
      types: typedData.types,
      message: typedData.value,
    })

    // broadcast onchain
    const broadcastOnchainResult =
      await lensClient.transaction.broadcastOnchain({
        id,
        signature: signed,
      })

    const onchainRelayResult = broadcastOnchainResult.unwrap()

    if (onchainRelayResult.__typename === 'RelayError') {
      throw new Error('Something went wrong enabling the profile manager')
    }

    setIsManagerEnabled(true)
    console.log(
      `Successfully changed profile managers with transaction with id ${onchainRelayResult}, txHash: ${onchainRelayResult.txHash}`
    )
  }

  /**
   * Follow a given ProfileId.
   * @returns {Promise<user>} A promise that resolves if the follow action has been succesfully.
   */
  const follow = async (profileId: string) => {
    try {
      const res = await lensClient.profile.follow({ follow: [{ profileId }] })

      if (res.isFailure()) {
        console.error(res.error)

        process.exit(1)
      }

      const data = res.value

      if (!isRelaySuccess(data)) {
        console.error(data.reason)

        process.exit(1)
      }
      const completion = await lensClient.transaction.waitUntilComplete({
        forTxId: data.txId,
      })

      if (completion?.status === LensTransactionStatusType.Failed) {
        console.error(completion.reason)

        process.exit(1)
      }

      return completion
    } catch (error) {
      console.log('Error following on lens: ', error)
    }
  }

  /**
   * Unfollow a given ProfileId.
   * @returns {Promise<user>} A promise that resolves if the unfollow action has been succesfully.
   */
  const unfollow = async (profileId: string) => {
    try {
      const res = await lensClient.profile.unfollow({ unfollow: [profileId] })

      if (res.isFailure()) {
        console.error(res.error)

        process.exit(1)
      }

      const data = res.value

      if (!isRelaySuccess(data)) {
        console.error(data.reason)

        process.exit(1)
      }
      const completion = await lensClient.transaction.waitUntilComplete({
        forTxId: data.txId,
      })

      if (completion?.status === LensTransactionStatusType.Failed) {
        console.error(completion.reason)

        process.exit(1)
      }

      return completion
    } catch (error) {
      console.log('Error unfollowing on lens: ', error)
    }
  }
  /**
   * Like a given publicationId.
   * @returns {Promise<post>} A promise that resolves if the liking post action has been succesfully..
   */
  const like = async (publicationId: string) => {
    try {
      await lensClient.publication.reactions.add({
        for: publicationId,
        reaction: PublicationReactionType.Upvote,
        app: APP_ID_HANDLEFINDER,
      })
    } catch (error) {
      console.log('Error liking post on lens: ', error)
    }
  }

  /**
   * Unlike a given publicationId.
   * @returns {Promise<post>} A promise that resolves if the unliking post action has been succesfully.
   */
  const unlike = async (publicationId: string) => {
    try {
      await lensClient.publication.reactions.remove({
        for: publicationId,
        reaction: PublicationReactionType.Upvote,
        app: APP_ID_HANDLEFINDER,
      })
    } catch (error) {
      console.log('Error unliking post on lens: ', error)
    }
  }

  /**
   * Comment to a given publicationId.
   * @returns {Promise<post>} A promise that resolves if the comment post action has been succesfully.
   */
  const comment = async (
    publicationId: string,
    content: string,
    isMomokaPub: boolean
  ) => {
    try {
      const metadata = await createTextMetadata(content)
      const cid = await uploadJSON(metadata)

      if (isMomokaPub) {
        const momokaComment = await lensClient.publication.commentOnMomoka({
          commentOn: publicationId,
          contentURI: `ipfs://${cid}`,
        })

        if (momokaComment.isFailure()) {
          console.error(momokaComment.error)
          process.exit(1)
        }
      } else {
        const lensComment = await lensClient.publication.commentOnchain({
          commentOn: publicationId,
          contentURI: `ipfs://${cid}`,
        })

        if (lensComment.isFailure()) {
          console.error(lensComment.error)
          process.exit(1)
        }
        const data = lensComment.value

        if (!isRelaySuccess(data)) {
          console.error(data.reason)
          process.exit(1)
        }
      }
    } catch (error) {
      console.log('Error while comment post on lens: ', error)
    }
  }

  const createTextMetadata = async (content: string) => {
    return textOnly({
      appId: APP_ID_HANDLEFINDER,
      content,
    })
  }

  /**
   * Fetch the criteria to collect a Post.
   * @returns {Promise<post>} A promise that resolves the collect criteria for a post.
   */
  const collectCriteria = async (publicationId: string) => {
    try {
      const post: PostFragment = (await getPostById(
        publicationId as string
      )) as PostFragment

      if (!post) throw new Error('error fetching post by Id')

      const canCollect = post.operations.canCollect

      const settings = post.openActionModules.find(
        (module: any): module is CollectActionModuleSettings =>
          [
            'SimpleCollectOpenActionModule',
            'MultirecipientFeeCollectOpenActionModule',
          ].includes(module.type)
      )

      return { canCollect, settings, post }
    } catch (error) {
      console.log('Error while fetching collect criteria of the post: ', error)
    }
  }

  /**
   * Collect a given publicationId.
   * @returns {Promise<post>} A promise that resolves if the collect action for a post has been succesfully.
   */

  const collect = async (publicationId: string) => {
    try {
      const post: PostFragment = (await getPostById(
        publicationId as string
      )) as PostFragment

      if (!post) throw new Error('error fetching post by Id')

      ////////////////////////////

      const settings = post.openActionModules.find(
        (module: any): module is CollectActionModuleSettings =>
          [
            'SimpleCollectOpenActionModule',
            'MultirecipientFeeCollectOpenActionModule',
          ].includes(module.type)
      )

      if (settings && settings.amount.value !== '0') {
        const feeAmount = settings.amount
        const collectActionModuleAddress = settings.contract.address

        const hub = new Contract(
          feeAmount.asset.contract.address,
          erc20Abi,
          signer
        )
        const txFee = await hub.approve(
          collectActionModuleAddress,
          parseEther(feeAmount.value)
        )

        await txFee.wait()

        ////////////////////////////////////////

        if (settings.__typename === 'SimpleCollectOpenActionSettings') {
          const typedDataResult =
            await lensClient.publication.actions.createActOnTypedData({
              actOn: {
                simpleCollectOpenAction: true,
              },
              for: publicationId,
            })

          const data = typedDataResult.value

          if (!signer) throw new Error('Signer error, try loggin in again')
          const signature = await signer.signTypedData(
            data.typedData.domain,
            data.typedData.types,
            data.typedData.value
          )

          const broadcastResult = await lensClient.transaction.broadcastOnchain(
            { id: data.id, signature }
          )

          if (broadcastResult.isFailure()) {
            console.error(broadcastResult.error)
            process.exit(1)
          }

          const broadcastResultValue = broadcastResult.value
          if (!isRelaySuccess(broadcastResultValue)) {
            console.error(broadcastResultValue.reason)
            process.exit(1)
          }

          const completion = await lensClient.transaction.waitUntilComplete({
            forTxId: broadcastResultValue.txId,
          })

          if (completion?.status === LensTransactionStatusType.Failed) {
            console.error(completion.reason)
            process.exit(1)
          }

          return { isSuccess: true }
        } else {
          const typedDataResult =
            await lensClient.publication.actions.createActOnTypedData({
              actOn: {
                multirecipientCollectOpenAction: true,
              },
              for: publicationId,
            })

          const data = typedDataResult.value

          if (!signer) throw new Error('Signer error, try loggin in again')
          const signature = await signer.signTypedData(
            data.typedData.domain,
            data.typedData.types,
            data.typedData.value
          )

          const broadcastResult = await lensClient.transaction.broadcastOnchain(
            { id: data.id, signature }
          )

          if (broadcastResult.isFailure()) {
            console.error(broadcastResult.error)
            process.exit(1)
          }

          const broadcastResultValue = broadcastResult.value

          if (!isRelaySuccess(broadcastResultValue)) {
            console.error(broadcastResultValue.reason)
            process.exit(1)
          }

          const completion = await lensClient.transaction.waitUntilComplete({
            forTxId: broadcastResultValue.txId,
          })

          if (completion?.status === LensTransactionStatusType.Failed) {
            console.error(completion.reason)
            process.exit(1)
          }

          return { isSuccess: true }
        }
      } else {
        const result = await lensClient.publication.actions.actOn({
          actOn: {
            simpleCollectOpenAction: true,
          },

          for: publicationId,
        })

        if (result.isFailure()) {
          console.error(result.error)
          process.exit(1)
        }
        const data = result.value

        if (!isRelaySuccess(data)) {
          console.error(data.reason)
          process.exit(1)
        }

        const completion = await lensClient.transaction.waitUntilComplete({
          forTxId: data.txId,
        })

        if (completion?.status === LensTransactionStatusType.Failed) {
          console.error(completion.reason)
          process.exit(1)
        }

        return { isSuccess: true }
      }
    } catch (error) {
      console.log('Error while collecting post on lens: ', error)
    }
  }

  /**
   * Signs out from Lens.
   * @returns {Promise<void>} A promise that resolves when the user is signed out.
   */
  const signOut = async () => {
    try {
      await lensClient.authentication.logout()
      await handleProfileId(undefined)
      setAuth(undefined)
      setIsManagerEnabled(null)
    } catch (error) {
      console.log('Error signing out from lens: ', error)
    }
  }

  const exploreOrbClubPublications = async (
    clubId: string
  ): Promise<ExplorePublicationsResponse['explorePublications']['items']> => {
    let allPublications: ExplorePublicationsResponse['explorePublications']['items'] =
      []
    let hasNextPage = true
    let cursor: string | null = null
    const sevenDaysAgo = new Date()
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7)

    while (hasNextPage) {
      try {
        const variables: ExplorePublicationsVariables = {
          request: {
            limit: 'Fifty',
            orderBy: ExplorePublicationsOrderByType.Latest,
            where: {
              publicationTypes: 'POST',
              metadata: {
                tags: {
                  oneOf: [clubId],
                },
                publishedOn: ['orb'],
              },
            },
            ...(cursor && { cursor }),
          },
        }

        const { data } = await apolloClient.query<
          ExplorePublicationsResponse,
          ExplorePublicationsVariables
        >({
          query: EXPLORE_PUBLICATIONS_QUERY,
          variables,
        })

        const filteredPublications = data.explorePublications.items.filter(
          (pub) => new Date(pub.createdAt) >= sevenDaysAgo
        )

        allPublications = [...allPublications, ...filteredPublications]

        if (
          data.explorePublications.pageInfo.next &&
          new Date(
            data.explorePublications.items[
              data.explorePublications.items.length - 1
            ].createdAt
          ) >= sevenDaysAgo
        ) {
          cursor = data.explorePublications.pageInfo.next
        } else {
          hasNextPage = false
        }
      } catch (error) {
        console.error('Error fetching explore publications:', error)
        hasNextPage = false
      }
    }

    return allPublications
  }

  const value: LensValue = {
    user,
    isDisconnecting,
    profileId: user.profileId,
    isCheckingLocalKeys,
    isCheckingLensData,
    handleLensDisconnecting: handleDisconnecting,
    getProfile,
    getExploreProfiles,
    getProfilesById,
    getProfilesByHandle,
    getPostsById,
    getPostById,
    getUserPosts,
    getUserLatestsPosts,
    getUserLatestsFollowers,
    isFollowedByMe,
    getUserTopFollowers,
    client: lensClient,
    signOut,
    hasLensHandle: Boolean(user.profileId),
    handle: user.handle,
    auth,
    isManagerEnabled,
    handleJWT,
    checkIsJWTstoraged,
    getManagedProfiles,
    authenticate,
    authenticateByRefreshToken,
    follow,
    unfollow,
    like,
    unlike,
    comment,
    collectCriteria,
    collect,
    exploreOrbClubPublications,
    enableProfileManager,
  }

  return <LensContext.Provider value={value}>{children}</LensContext.Provider>
}

/**
 * Lens context hook.
 * @return Lens context value.
 */
export function useLens() {
  return useContext(LensContext)
}
