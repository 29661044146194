import { categories, categoryKeywords } from './constants/types'
import { PROJECT_MODE } from './constants/api'

export const isProductionMode = PROJECT_MODE === 'production'
export const isDevelopMode = PROJECT_MODE === 'development'

export const addRenderPropertyAndCategory = (interests: any) => {
 return interests.map((interestObj) => {
  let render = interestObj.interest.toLowerCase().replace(/_/g, ' ')
  render = render.replace(/\s+/g, ' ')

  const category = categories.find((cat) => {
   const keywords = categoryKeywords[cat]
   return keywords.some((keyword) => render.includes(keyword))
  })

  return { ...interestObj, render, category }
 })
}

export const parseMentions = (text: string): string => {
 const pattern = /@\w+\/\w+/g

 const replacer = (match: string) => {
  const dynamicHandle = match.split('/')[1]
  return `@${dynamicHandle}`
 }

 return text.replace(pattern, replacer)
}

export const checkIfLivepeerGateway = (url: string) => {
 return url.includes('playback.livepeer.studio')
}

export const changeLensGateway = (url: string) => {
 const imageCID = getCidFromGateway(url)
 return imageCID ? nftStorageGateway(imageCID) : url
}

export const getCidFromGateway = (url: string) => {
 if (url.startsWith('ipfs://')) {
  return url.substring(7)
 }

 const regex = /\/ipfs\/(.+)$/
 const match = url.match(regex)

 if (match && match[1]) {
  return match[1]
 }
 return null
}

export const nftStorageGateway = (cid: string) =>
 cid ? `https://${cid}.ipfs.nftstorage.link` : ''

export const getAvatarUrl = (user: any) => {
 if (!user) return
 const { metadata } = user

 if (metadata?.picture?.optimized?.uri) {
  return metadata?.picture?.optimized?.uri
 } else if (metadata?.picture?.image?.raw.uri) {
  return metadata?.picture?.image?.raw.uri
 } else return metadata?.picture?.rawURI
}

/**
 *
 * @param address The complete address to truncate
 * @param startLength The number of characters to show at the start of the address
 * @param endLength The number of characters to show at the end of the address
 * @returns The truncated address or an empty string if the address is not defined
 * @example truncateAddress("0x1234567890abcdef1234567890abcdef12345678") => "0x12345...5678"
 */
export const truncateAddress = (
 address: `0x${string}`,
 startLength = 5,
 endLength = 4
) => {
 if (!address) return ''
 const truncatedStart = address.slice(0, startLength)
 const truncatedEnd = address.slice(-endLength)
 return `${truncatedStart}...${truncatedEnd}`
}
