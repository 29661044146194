// ** React & Next
import { useEffect, useState } from 'react'
import Image from 'next/image'

// ** MUI Imports
import {
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'

// ** Hooks
import { Connector, useConnect } from 'wagmi'
import { useModalsActions } from '@/context/modals'

// ** Utils & Types
import { BaseMUIButtonProps, SignInStatus } from '@/types/custom'
import getWalletDetails from '@/helpers/getWalletDetails'
import { USER_REJECTED_CODE_ERROR } from '@/utils/constants/literals'
import { setSignInStatus } from '@/utils/helpers'

export const WalletOptions = () => {
  const { connectors, connectAsync } = useConnect()
  const { close: closeModal, open: openModal } = useModalsActions()

  const onConnect = async (connector: Connector) => {
    try {
      closeModal("connectWallet")
      await connectAsync({ connector })

      setSignInStatus(SignInStatus.pending)
      openModal("signInProcess");
    } catch (error: any) {
      if (error?.code === USER_REJECTED_CODE_ERROR) return;
    }
  };

  return (
    <Stack gap={1}>
      {connectors
        .filter(
          (connector: any, index: number, self: any) =>
            self.findIndex((c: any) => c.type === connector.type) === index
        )
        .map((connector: any) => {
          return (
            <WalletOption
              key={connector.uid}
              connector={connector}
              onClick={() => onConnect(connector)}
            />
          )
        })}
    </Stack>
  )
}

const WalletOption = ({
  connector,
  onClick,
}: {
  connector: Connector
  onClick: () => void
}) => {
  const [ready, setReady] = useState(false)
  const theme = useTheme()
  const isSmScreen = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    ;(async () => {
      const provider = await connector.getProvider()
      setReady(!!provider)
    })()
  }, [connector])

  return (
    <Button size="small" disabled={!ready} onClick={onClick} variant="outlined">
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        width={'100%'}
        m={1}
      >
        <Typography>
          {connector.id === 'injected'
            ? 'Browser wallet'
            : getWalletDetails(connector.name).name}
        </Typography>
        <Image
          src={getWalletDetails(connector.name).logo}
          width={isSmScreen ? 20 : 30}
          height={isSmScreen ? 20 : 30}
          alt=""
        />
      </Stack>
    </Button>
  )
}

type Props = BaseMUIButtonProps & {
  /**
   * The text to be displayed inside the button.
   * @default "Connect wallet"
   */
  label?: string
  /**
   * Callback fired before the subscription takes place.
   */
  beforeOpen?: () => void
}

export const LoginWallet = ({
  variant = 'contained',
  size = 'medium',
  label = 'Login',
  beforeOpen,
}: Props) => {
  const { open: openModal } = useModalsActions()
  return (
    <>
      <Button
        variant={variant}
        size={size}
        onClick={() => {
          beforeOpen?.()
          openModal('connectWallet')
        }}
      >
        <Stack direction="row">{label}</Stack>
      </Button>
    </>
  )
}
