// ** React Imports
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

// ** Hooks
import { useAccount, useConfig, useDisconnect } from 'wagmi'
import { useLens } from './lens'
import { useLocalStorage } from '../hooks/useLocalStorage'
import { useHandleNetwork, isSupportedChainId } from './network'

// ** Utils
import { UserSessionStatus, SignInStatus } from '../types/custom'
import { ChainId } from '../types/network'
import { getSignInStatus, removeSignInStatus, setSignInStatus, wipeCredentials } from '../utils/helpers'
import { CredentialType } from '../types/auth/auth'
import { useModalsActions } from './modals'
import { UserSession } from '@/types/apps/users'
import { isProductionMode } from '@/utils'

interface SessionValue {
  isLoading: boolean
  user: UserSession
  userProfiles: any[]
  notLoggedIn: boolean
  connectedNotLogged: boolean
  loggedIn: boolean
  isLoadingSignIn: boolean
  logOut: () => Promise<any>
  updateUserProfiles: (profiles: any[]) => void
  handleSignInStatus: (status: SignInStatus) => void
}

const SessionContext = createContext<SessionValue>({} as SessionValue)

type SessionProviderProps = {
  children: ReactNode
}

/**
 * Session context provider
 */
export function SessionProvider({ children }: SessionProviderProps) {
  const { chainId, unsupportedNetwork } = useHandleNetwork()
  const { open: openModal } = useModalsActions()
  const { address, isConnected, isConnecting, isReconnecting } = useAccount()
  const { disconnect } = useDisconnect()
  const { chains } = useConfig()
  const {
    user,
    profileId,
    signOut: lensSignOut,
    isCheckingLocalKeys: isLensLoading,
    isCheckingLensData,
    isDisconnecting: isLensDisconnecting,
    handleLensDisconnecting,
    auth: lensSignature,
    isManagerEnabled,
    checkIsJWTstoraged,
    authenticateByRefreshToken,
    handleJWT,
  } = useLens()

  const [userProfiles, setUserProfiles] = useState<any[]>([])
  const [isSetUpLoading, setIsSetUpLoading] = useState(false)
  const [loggedAddress, setLoggedAddress] = useLocalStorage('address', null)

  const updateUserProfiles = (profiles: any[]) => {
    setUserProfiles(profiles)
  }

  /**
   * Whether localStorage keys are loading.
   */
  const areKeysLoading = isLensLoading

  const isAppDisconnecting = isLensDisconnecting;

  const isLoading = isLensLoading || isCheckingLensData

  const userStatus: UserSessionStatus = useMemo(() => {
    if (isConnected && profileId) {
      return UserSessionStatus.Logged
    } else if (isConnected && !profileId) {
      return UserSessionStatus.ConnectedNotLogged
    } else if (
      isConnecting ||
      isReconnecting ||
      areKeysLoading ||
      isSetUpLoading
    ) {
      return UserSessionStatus.Loading
    } else {
      return UserSessionStatus.NotLogged
    }
  }, [
    isConnected,
    loggedAddress,
    isConnecting,
    isReconnecting,
    areKeysLoading,
    profileId,
  ])

  const notLoggedIn = useMemo(
    () => userStatus === UserSessionStatus.NotLogged,
    [userStatus]
  )
  const connectedNotLogged = useMemo(
    () => userStatus === UserSessionStatus.ConnectedNotLogged,
    [userStatus]
  )
  const loggedIn = useMemo(
    () => userStatus === UserSessionStatus.Logged,
    [userStatus]
  )
  const isLoadingSignIn = useMemo(
    () => userStatus === UserSessionStatus.Loading,
    [userStatus]
  )

  /**
   * Sign out when the user changes.
   */
  useEffect(() => {
    cleanSessionAddress()
  }, [address])

  useEffect(() => {
    if (isConnecting || isReconnecting) return

    if (isConnected) {
      fetchProvider()
    }
  }, [isConnected, chainId, isConnecting, isReconnecting])

  /**
   * Sign in with lens when the user connects the wallet.
   */
  useEffect(() => {
    const signInStatus = getSignInStatus()
    if (
      signInStatus === SignInStatus.pending &&
      !areKeysLoading &&
      !unsupportedNetwork &&
      !isAppDisconnecting &&
      !isCheckingLensData &&
      address &&
      !profileId
    ) {
      logInWithLens()
    }
  }, [chainId, address, profileId])

  useEffect(() => {
    if (isCheckingLensData) return
    checkSignInStatus();
  }, [lensSignature, isManagerEnabled])

  /*************************************************
   *                  Functions                    *
   *************************************************/

  const checkSignInStatus = () => {
    if (isManagerEnabled === null) return
    const signInStatus = getSignInStatus()
    if (signInStatus === SignInStatus.rejected) return

    if (
      (!lensSignature && !checkIsJWTstoraged())
      || (lensSignature && !isManagerEnabled)
    ) {
      setSignInStatus(SignInStatus.pending)
    }
  }

  async function logInWithLens() {
    if (unsupportedNetwork) return
    try {
      if (!address) throw new Error('No address found.')

      if (await checkIsJWTstoraged()) {
        const profile = await authenticateByRefreshToken()

        if (profile) {
          setSignInStatus(SignInStatus.completed)
          setLoggedAddress(address)
          return
        }
      }

      if (lensSignature) {
        await handleJWT(
          address,
          lensSignature.message,
          lensSignature.accessToken,
          address
        )
        if (isManagerEnabled) {
          setSignInStatus(SignInStatus.completed)
        }
        setLoggedAddress(address)
        return
      }

      const currentSignInStatus = getSignInStatus()
      if (currentSignInStatus !== undefined) {
        return;
      }

      openModal('signInProcess')
    } catch (error) {
      console.log('Error at login process: ', error)
      setSignInStatus(SignInStatus.pending)
    }
  }

  const fetchProvider = async () => {
    setIsSetUpLoading(true)
    try {
      let connectedAddress = address?.toLowerCase()
      if (!connectedAddress) return

      const chain = chains.find((chain) => chain.id === chainId)
      if (!chain) return

      const isSupported = isSupportedChainId(chain.id as ChainId)
      if (!isSupported) return

      setLoggedAddress(connectedAddress)
    } catch (error) {
      console.log('Error setting up provider: ', error)
    } finally {
      setIsSetUpLoading(false)
    }
  }

  const handleSignInStatus = (status: SignInStatus) => {
    setSignInStatus(status)
  }

  /**
   * Clean the session
   */
  const cleanSession = () => {
    localStorage.removeItem('jwt')
    localStorage.removeItem('accessToken')
    removeSignInStatus()
    setLoggedAddress(null)
  }

  /**
   * Clean the session when the adress changes.
   */
  const cleanSessionAddress = async () => {
    if (
      loggedAddress &&
      address &&
      loggedAddress.toLowerCase() !== address.toLowerCase()
    ) {
      await logOut()
    }
  }

  async function logOut() {
    handleLensDisconnecting(true)

    // Logout from lens
    await lensSignOut()

    wipeCredentials(address as `0x${string}`, CredentialType.all, isProductionMode)

    // Disconnect wallet from wagmi
    disconnect()

    // Remove JWT token
    cleanSession()

    handleLensDisconnecting(false)
  }

  const value: SessionValue = {
    isLoading,
    user,
    userProfiles,
    updateUserProfiles,
    notLoggedIn,
    connectedNotLogged,
    loggedIn,
    isLoadingSignIn,
    handleSignInStatus,
    logOut,
  }

  return (
    <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
  )
}

export function useSession(): NonNullable<SessionValue> {
  return useContext(SessionContext)
}
