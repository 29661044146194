// ** React Imports
import { ReactNode, createContext, useContext } from 'react'

// ** Pinata Cloud
import { PinataPinResponse } from '@pinata/sdk'

// ** Utils
import { PINATA_JWT, PINNING_PINATA_API_URL } from '@/utils/constants/api'

/**
 * IPFSStorage interface
 */
interface IPFSStorage {
  uploadImage: (image: File) => Promise<string | undefined>
  uploadJSON: (json: any) => Promise<string | undefined>
}

const IPFSStorageContext = createContext<IPFSStorage>({} as IPFSStorage)

/**
 * IPFS-Storage context provider
 */
export function IPFSStorageProvider({ children }: { children: ReactNode }) {
  const uploadImage = async (image: File) => {
    try {
      const data = new FormData()
      data.append('file', image)

      const res = await fetch(`${PINNING_PINATA_API_URL}/pinFileToIPFS`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${PINATA_JWT}`,
        },
        body: data,
      })
      const resData: PinataPinResponse = await res.json()
      return resData.IpfsHash
    } catch (error) {
      console.log(`Error uploading image ${error}`)
    }
  }

  const uploadJSON = async (json: any) => {
    try {
      const data = JSON.stringify(json)

      const res = await fetch(`${PINNING_PINATA_API_URL}/pinJSONToIPFS`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${PINATA_JWT}`,
        },
        body: data,
      })
      const resData: PinataPinResponse = await res.json()
      return resData.IpfsHash
    } catch (error) {
      console.log(`Error uploading image ${error}`)
    }
  }

  const value: IPFSStorage = {
    uploadImage,
    uploadJSON,
  }

  return (
    <IPFSStorageContext.Provider value={value}>
      {children}
    </IPFSStorageContext.Provider>
  )
}

export function useIPFSStorage() {
  return useContext(IPFSStorageContext)
}
