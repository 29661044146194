import { polygon } from 'viem/chains'

/* CONFIG =========================================================================== */

export const POLYGON_ID = polygon.id

export const DEFAULT_NETWORK = POLYGON_ID

export const availableNetworks = [POLYGON_ID]

/* NETWORKS & LIBREE_NETWORKS ======================================================= */

export const NETWORKS = [polygon]

export const LIBREE_NETWORKS = NETWORKS.filter(
 (network) =>
  availableNetworks.includes(network.id) &&
  !network.testnet
)
