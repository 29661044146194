// ** React Imports
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react'

// ** MUI Imports
import {
  Avatar,
  Box,
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'

// ** Hooks
import { useLens } from '@/context/lens'
import { useAccount } from 'wagmi'

// ** Components
import { BaseSignature } from './BaseSignature'

// ** Utils & Types

import { SignatureStatus } from '.'
import { generateLensSignature } from '@/utils/helpers'

export type LensProfileData = {
  id: string
  handle: string
  name: string
  img: string
}

type LensSign = {
  status: SignatureStatus
  profiles: LensProfileData[]
}

type LensSignatureProps = LensSign & {
  setStatus: Dispatch<SetStateAction<SignatureStatus>>
}

type ProfileBoxProps = {
  profile: LensProfileData
  handleAuthenticate: (id?: string) => void
}

const ProfileBox = ({ profile, handleAuthenticate }: ProfileBoxProps) => {
  const theme = useTheme()
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        p: 0.5,
        gap: isMdScreen ? 1 : 2,
        borderRadius: '0.5rem',
        border: `1px solid ${theme.palette.divider}`,
        width: '100%',
      }}
    >
      <Stack direction={'row'} alignItems={'center'} gap={{ xs: 1, md: 2 }}>
        <Avatar src={profile.img} sx={{ width: 32, height: 32 }} />
        <Stack direction={'column'} gap={0}>
          <Typography variant="subtitle1" color="textPrimary">
            {profile.name}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {profile.handle}
          </Typography>
        </Stack>
      </Stack>
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={() => handleAuthenticate(profile.id)}
      >
        Login
      </Button>
    </Box>
  )
}

type ProfileListProps = {
  profiles: LensProfileData[]
  handleAuthenticate: (id?: string) => void
}

const ProfileList = ({ profiles, handleAuthenticate }: ProfileListProps) => (
  <Stack
    direction={'column'}
    alignItems={'center'}
    gap={1}
    sx={{ overflowY: 'auto' }}
  >
    {profiles.map((item) => (
      <ProfileBox
        key={item.id}
        profile={item}
        handleAuthenticate={handleAuthenticate}
      />
    ))}
  </Stack>
)

export const LensSignature = ({
  profiles,
  status,
  setStatus,
}: LensSignatureProps) => {
  const [selectedProfile, setSelectedProfile] = useState<string>()

  const { authenticate } = useLens()
  const { address } = useAccount()
  const theme = useTheme()
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'))

  const handleAuthenticate = useCallback(
    async (id?: string): Promise<any | undefined> => {
      setStatus('pending')
      try {
        if (id) setSelectedProfile(id)
        const walletId = address?.toLowerCase()
        if (!walletId) throw new Error('No wallet connected ')
        const jwt = await generateLensSignature(authenticate, id)
        if (jwt === null) throw new Error('No jwt generated.')
        setStatus('success')
        return true
      } catch (error) {
        console.log('Error authenticating with lens', error)
        setStatus('error')
      }
    },
    [setStatus]
  )

  const title = useMemo(() => {
    if (!profiles.length) return 'Login'
    if (isMdScreen) return 'Login'
    return 'Login with Lens'
  }, [isMdScreen, profiles.length])

  if (status === undefined && !profiles.length) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          p: 2,
          my: 0.5,
          borderRadius: '0.5rem',
          border: `1px solid ${theme.palette.divider}`,
          width: '100%',
          minHeight: '4rem',
        }}
      >
        <Typography
          variant="subtitle2"
          color="textSecondary"
          textAlign={'center'}
        >
          You must have a Lens handle to sign-in
        </Typography>
      </Box>
    )
  }

  return (
    <BaseSignature
      status={status}
      setStatus={setStatus}
      callFunction={() => handleAuthenticate(selectedProfile)}
      title={title}
      subTitle={profiles.length > 1 ? '(Select one)' : undefined}
      ctaLabel="Login"
      ctaContent={
        profiles.length && status === undefined ? (
          <ProfileList
            profiles={profiles}
            handleAuthenticate={handleAuthenticate}
          />
        ) : undefined
      }
    />
  )
}
